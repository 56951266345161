<template>
  <div class="anagrafica pb-2">
    <h3 class="mt-2 text-left">
      <b-icon
        icon="person"
        variant="secondary"
        font-scale="1.2"
        class="mr-1"
      ></b-icon>
      Profilo
      <b-button
        class="ml-2 mb-1 px-2 px-sm-3 px-md-5 ml-3"
        v-if="!editIndexes[0]"
        font-scale="0.8"
        type="submit"
        variant="secondary"
        @click="setEditIndex(0, true)"
      >
        Modifica
      </b-button>
    </h3>

    <b-form @submit.prevent="onSubmit">
      <section
        :class="[
          'shadowedCard border general_border border-white p-2 p-md-3 p-lg-4 mt-1',
          { 'read-only-opacity': !editIndexes[0] },
        ]"
      >
        <div
          class="d-flex flex-column flex-md-row h-100 align-items-md-stretch"
        >
          <div
            class="d-flex flex-column justify-content-center align-items-center justify-content-md-start text-center col-12 col-md-3 col-xl-3 mb-4 mb-md-0 p-3 p-md-0"
          >
            <b-avatar
              variant="white"
              :src="avatarPath"
              rounded
              :size="$root.isMobile ? '5rem' : '10rem'"
              class="mx-md-auto mb-md-2"
            >
            </b-avatar>
            <div class="mt-2 d-flex flex-column justify-content-around px-4">
              <b-button
                @click="
                  editIndexes[0] ? $refs.uploadComponentRef.click() : null
                "
                class="mb-2"
                variant="secondary"
              >
                Scegli immagine
              </b-button>
              <b-button
                v-if="avatarPath"
                @click="editIndexes[0] ? eliminaAvatarProfilo() : null"
                class=""
                variant="outline-danger mb-2"
              >
                Elimina
              </b-button>
              <div class="d-none">
                <UploadComponent
                  dest="profilo"
                  class="w-100 mb-n2 pb-1"
                  @show-carica-button="handleFileSelection($event)"
                  v-on:upload-result="handleUpload($event)"
                  ref="uploadComponentRef"
                />
              </div>
              <b-button
                v-if="showCaricaButton"
                @click="callDoUploadOnUploadComponent"
                class="mb-md-3"
                variant="secondary"
              >
                Carica Immagine
              </b-button>
            </div>
          </div>
          <b-row cols="1" cols-md="2" cols-xl="3">
            <b-col>
              <b-row>
                <b-form-group
                  label="Titolo"
                  label-for="title"
                  class="text-left col-3 col-xl-2 p-0 pr-1"
                >
                  <b-form-input
                    v-capitalize-first
                    v-if="!editIndexes[0]"
                    class="my-3 text-capitalize"
                    name="title"
                    type="text"
                    placeholder="Sig..."
                    v-model="anagrafica.title"
                    :readonly="!editIndexes[0]"
                  ></b-form-input>
                  <b-form-select
                    v-capitalize-first-select
                    v-else
                    class="mb-3 mt-2 custom-select text-capitalize"
                    name="title"
                    v-model="anagrafica.title"
                    :options="titleOptions"
                  >
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Nome*"
                  label-for="name"
                  class="text-left col p-0"
                >
                  <b-form-input
                    v-capitalize-first
                    class="my-3"
                    name="name"
                    type="text"
                    placeholder="Nome..."
                    v-model="anagrafica.name"
                    :readonly="!editIndexes[0]"
                  ></b-form-input>
                </b-form-group>
              </b-row>
            </b-col>

            <b-col>
              <b-form-group
                label="Cognome*"
                label-for="surname"
                class="text-left"
              >
                <b-form-input
                  v-capitalize-first
                  class="my-3"
                  name="surname"
                  type="text"
                  placeholder="cognome..."
                  v-model="anagrafica.surname"
                  :readonly="!editIndexes[0]"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Data di nascita*"
                label-for="birthday"
                class="text-left"
              >
                <b-form-input
                  v-capitalize-first
                  class="my-3"
                  name="birthday"
                  type="date"
                  v-model="anagrafica.birthday"
                  :readonly="!editIndexes[0]"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Genere"
                label-for="gender"
                class="text-left d-flex flex-column justify-content-around"
              >
                <b-form-radio-group
                  v-model="anagrafica.gender"
                  name="gender-options"
                  class="d-flex mt-2"
                >
                  <b-form-radio value="maschile">uomo</b-form-radio>
                  <b-form-radio value="femminile">donna</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label="Codice fiscale*"
                label-for="cf"
                class="text-left"
              >
                <b-form-input
                  v-capitalize-first
                  class="my-3 text-uppercase"
                  name="cf"
                  type="text"
                  placeholder="Codice fiscale..."
                  maxlength="16"
                  minlength="16"
                  v-model="anagrafica.cf"
                  :readonly="!editIndexes[0]"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Numero Tessera Sanitaria"
                label-for="ts_number"
                class="text-left"
              >
                <b-form-input
                  v-capitalize-first
                  class="my-3"
                  name="ts_number"
                  type="text"
                  placeholder="Numero tessera..."
                  v-model="anagrafica.ts_number"
                  maxlength="20"
                  minlength="20"
                  :readonly="!editIndexes[0]"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Nazione di nascita*"
                label-for="nazione_nascita"
                class="text-left"
              >
                <b-form-input
                  v-capitalize-first
                  v-if="!editIndexes[0]"
                  class="my-3 text-capitalize"
                  name="nazione_nascita"
                  type="text"
                  placeholder="..."
                  v-model="anagrafica.nazione_nascita"
                  readonly
                ></b-form-input>
                <b-form-select
                  v-capitalize-first-select
                  v-else
                  class="mb-3 mt-2 text-capitalize"
                  :options="nazioniOptions"
                  name="nazione_nascita"
                  v-model="anagrafica.nazione_nascita"
                  required
                >
                </b-form-select>
              </b-form-group>
            </b-col>
            <template v-if="user.type === 0">
              <b-col>
                <b-form-group
                  label="Medico di base"
                  label-for="medico_base"
                  class="text-left"
                >
                  <b-form-input
                    class="my-3"
                    name="medico_base"
                    type="text"
                    v-capitalize-first
                    placeholder="Medico di base..."
                    v-model="anagrafica.medico_base"
                    :readonly="!editIndexes[0]"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  v-if="editIndexes[0]"
                  label="Asl/Asp/Ats di riferimento"
                  label-for="denominazione_asl"
                  class="text-left"
                >
                  <b-form-select
                    v-capitalize-first-select
                    class="mb-3 mt-2 custom-select text-capitalize"
                    name="denominazione_asl"
                    v-model="anagrafica.denominazione_asl"
                    :disabled="!editIndexes[0]"
                  >
                    <b-form-select-option
                      class="text-capitalize"
                      v-for="aslOption in asl"
                      :key="aslOption"
                      :value="aslOption"
                      >{{ aslOption }}</b-form-select-option
                    >
                    <b-form-select-option :value="null"
                      >Scegli azienda sanitaria locale</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  v-else
                  label="Asl/Asp/Ats di riferimento"
                  label-for="denominazione_asl"
                  class="text-left"
                >
                  <b-form-input
                    v-capitalize-first
                    readonly
                    placeholder="Asl/Asp/Ats di riferimento..."
                    class="my-3 text-capitalize"
                    name="denominazione_asl"
                    v-model="anagrafica.denominazione_asl"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </template>
            <template v-else-if="user.type === 1">
              <b-col>
                <b-form-group
                  label="Ordine"
                  label-for="tipo_albo"
                  class="text-left"
                >
                  <b-form-input
                    v-capitalize-first
                    v-if="!editIndexes[0]"
                    class="my-3 text-capitalize"
                    name="tipo_albo"
                    type="text"
                    placeholder="Albo ordine..."
                    v-model="anagrafica.tipo_albo"
                    :readonly="!editIndexes[0]"
                  ></b-form-input>
                  <b-form-select
                    v-capitalize-first-select
                    v-else
                    class="mb-3 mt-2 custom-select text-capitalize"
                    name="tipo_albo"
                    v-model="anagrafica.tipo_albo"
                    :options="tipoAlboOptions"
                  >
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="
                    editIndexes[0]
                      ? 'ID Iscrizione Albo**'
                      : 'ID Iscrizione Albo'
                  "
                  label-for="id_albo"
                  class="text-left"
                  :description="
                    editIndexes[0] ? '**Includere la sigla provincia' : ''
                  "
                >
                  <b-form-input
                    class="my-3 text-capitalize"
                    name="id_albo"
                    type="text"
                    placeholder="ID ordine..."
                    v-model="anagrafica.id_albo"
                    :readonly="!editIndexes[0]"
                    :state="editIndexes[0] ? validIdOrdine : null"
                    @input="
                      (val) =>
                        (anagrafica.id_albo = anagrafica.id_albo.toUpperCase())
                    "
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </template>
          </b-row>
        </div>
        <p class="mb-0 w-100 text-right pt-3" v-if="editIndexes[0]">
          <b-button
            type="button"
            variant="outline-secondary  mx-2"
            @click.prevent="handleAnnulla"
            >Annulla</b-button
          >
          <b-button
            type="submit"
            variant="secondary  mx-2"
            :disabled="!enableBtn"
            >Salva
          </b-button>
        </p>
      </section>

      <b-row>
        <b-col class="px-0 col-12 col-lgx-6">
          <div class="mr-lgx-2">
            <h3 class="mt-2 pt-4 text-left">
              <b-icon
                icon="geo"
                variant="secondary"
                font-scale="1.2"
                class="mr-1"
              ></b-icon>
              Residenza
              <b-button
                class="ml-2 mb-1 px-2 px-sm-3 px-md-5 ml-3"
                v-if="!editIndexes[1]"
                font-scale="0.8"
                type="submit"
                variant="secondary"
                @click="setEditIndex(1, true)"
              >
                Modifica
              </b-button>
            </h3>
            <section
              :class="[
                'bg-white general_border shadowedCard p-2 p-md-3 p-lg-4 mt-2',
                { 'read-only-opacity': !editIndexes[1] },
              ]"
            >
              <b-row cols="1" cols-md="2" cols-xl="3">
                <template v-if="!editIndexes[1]">
                  <b-col>
                    <b-form-group
                      label="Regione*"
                      label-for="regione"
                      class="text-left"
                    >
                      <b-form-input
                        v-capitalize-first
                        class="my-3 text-capitalize"
                        name="regione"
                        type="text"
                        placeholder="Regione..."
                        v-model="anagrafica.regione"
                        readonly
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Provincia*"
                      label-for="provincia"
                      class="text-left"
                    >
                      <b-form-input
                        v-capitalize-first
                        class="my-3 text-capitalize"
                        name="provincia"
                        type="text"
                        placeholder="Provincia..."
                        v-model="anagrafica.provincia"
                        readonly
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Comune*"
                      label-for="comune"
                      class="text-left"
                    >
                      <b-form-input
                        v-capitalize-first
                        class="my-3 text-capitalize"
                        name="comune"
                        type="text"
                        placeholder="Comune..."
                        v-model="anagrafica.comune"
                        readonly
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </template>
                <template v-else>
                  <b-col>
                    <b-form-group
                      label="Regione*"
                      label-for="regione"
                      class="text-left"
                    >
                      <b-form-select
                        v-capitalize-first-select
                        class="my-3 custom-select text-capitalize"
                        :options="regioni"
                        name="regione"
                        v-model="selected.regione"
                        @change="getProvince(selected)"
                        :disabled="!editIndexes[1]"
                      >
                        <b-form-select-option :value="null"
                          >Seleziona Regione</b-form-select-option
                        >
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Provincia*"
                      label-for="provincia"
                      class="text-left"
                    >
                      <b-form-select
                        v-capitalize-first-select
                        class="my-3 text-capitalize"
                        :options="province"
                        name="provincia"
                        v-model="selected.provincia"
                        @change="getComuni(selected)"
                        :disabled="!editIndexes[1]"
                      >
                        <b-form-select-option :value="null"
                          >Seleziona Provincia</b-form-select-option
                        >
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Comune*"
                      label-for="comune"
                      class="text-left"
                    >
                      <b-form-select
                        v-capitalize-first-select
                        class="my-3 text-capitalize"
                        name="comune"
                        v-model="selected.comune"
                        :key="
                          comuni && comuni.length
                            ? comuni[0].comune + comuni.length
                            : null
                        "
                        :disabled="!editIndexes[1]"
                      >
                        <b-form-select-option
                          v-for="(com, ind) in comuni"
                          :key="ind"
                          :value="com"
                          @change="setDomicilioData"
                          :selected="findComune(com)"
                          >{{ com.comune }}</b-form-select-option
                        >
                        <b-form-select-option :value="null"
                          >Seleziona Comune</b-form-select-option
                        >
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </template>
                <b-col>
                  <b-form-group
                    label="Indirizzo e numero civico*"
                    label-for="address"
                    class="text-left"
                  >
                    <b-form-input
                      v-capitalize-first
                      class="my-3 text-capitalize"
                      name="address"
                      required
                      type="text"
                      placeholder="Indirizzo..."
                      v-model="anagrafica.address"
                      :readonly="!editIndexes[1]"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="CAP*" label-for="cap" class="text-left">
                    <b-form-input
                      v-capitalize-first
                      class="my-3"
                      name="cap"
                      type="text"
                      required
                      placeholder="CAP..."
                      v-model="anagrafica.cap"
                      minlength="5"
                      maxlength="5"
                      :readonly="!editIndexes[1]"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <p class="mb-0 w-100 text-right pt-3" v-if="editIndexes[1]">
                <b-button
                  type="button"
                  variant="outline-secondary  mx-2"
                  @click.prevent="handleAnnulla"
                  >Annulla</b-button
                >
                <b-button
                  type="submit"
                  variant="secondary  mx-2"
                  :disabled="!enableBtn"
                  >Salva
                </b-button>
              </p>
            </section>
          </div>
        </b-col>
        <b-col class="px-0 col-12 col-lgx-6">
          <div class="ml-lgx-2">
            <h3 class="mt-2 pt-4 text-left">
              <b-icon
                icon="telephone"
                variant="secondary"
                font-scale="1.2"
                class="mr-1"
              ></b-icon>
              Contatti
              <b-button
                class="ml-2 mb-1 px-2 px-sm-3 px-md-5 ml-3"
                v-if="!editIndexes[2]"
                font-scale="0.8"
                type="submit"
                variant="secondary"
                @click="setEditIndex(2, true)"
              >
                Modifica
              </b-button>
            </h3>
            <section
              :class="[
                'shadowedCard p-2 p-md-3 p-lg-4 mt-2 general_border',
                { 'read-only-opacity': !editIndexes[2] },
              ]"
            >
              <b-row cols="1" cols-md="2" cols-xl="3">
                <b-col>
                  <b-form-group
                    label="Numero di telefono"
                    label-for="tel_number"
                    class="text-left"
                  >
                    <b-form-input
                      v-capitalize-first
                      required
                      class="my-3"
                      name="tel_number"
                      type="text"
                      placeholder="Telefono..."
                      v-model="anagrafica.tel_number"
                      minlength="9"
                      :readonly="!editIndexes[2]"
                      :state="
                        editIndexes[2] && anagrafica.tel_number
                          ? validNumber
                          : null
                      "
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <template v-if="user.type === 0">
                  <b-col>
                    <b-form-group
                      label="Telefono Domicilio"
                      label-for="home_number"
                      class="text-left"
                    >
                      <b-form-input
                        v-capitalize-first
                        class="my-3"
                        name="tel_number"
                        type="text"
                        placeholder="Telefono domicilio..."
                        v-model="anagrafica.home_number"
                        minlength="9"
                        :readonly="!editIndexes[2]"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Telefono di Riserva"
                      label-for="tel_number_secondary"
                      class="text-left"
                    >
                      <b-form-input
                        v-capitalize-first
                        class="my-3"
                        name="tel_number"
                        type="text"
                        placeholder="Telefono di riserva..."
                        v-model="anagrafica.tel_number_secondary"
                        minlength="9"
                        :readonly="!editIndexes[2]"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </template>
              </b-row>
              <p class="mb-0 w-100 text-right pt-3" v-if="editIndexes[2]">
                <b-button
                  type="button"
                  variant="outline-secondary mx-2"
                  @click.prevent="handleAnnulla"
                  >Annulla</b-button
                >
                <b-button
                  type="submit"
                  variant="secondary mx-2"
                  :disabled="!enableBtn"
                  >Salva
                </b-button>
              </p>
            </section>
            <b-row class="text-left mt-3">
              <h6
                v-if="!this.user.number_verified_at"
                @click="$router.push({ name: 'Home' })"
                class="ml-4 text-secondary underlined point"
              >
                <b-icon icon="arrow-left" font-scale="0.7" class="mx-1"></b-icon
                >torna alla verifica
              </h6>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-form>
    <b-modal
      id="delete-propic-confirm"
      size="lg"
      centered
      title="Conferma eliminazione"
      button-size="sm"
      cancel-title="No, Annulla"
      cancel-variant="popup-button mr-2"
      ok-title="Sì, Conferma"
      ok-variant="popup-button"
      footer-class="border-0"
      @ok="deleteUserPropic"
      @cancel="$bvModal.hide('delete-propic-confirm')"
    >
      Confermi di voler eliminare l'immagine del profilo?
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { cittadinoService } from "@/_services";
import * as moment from "moment/moment";
import UploadComponent from "@/app/common/components/utilities/UploadComponent.vue";
import { mapState, mapActions } from "vuex";
import { utilityService, userService } from "@/_services";

export default {
  name: "AnagraficaComponent",
  computed: {
    validNumber() {
      if (this.anagrafica.tel_number) {
        const prefix = this.anagrafica.tel_number.slice(0, 2);
        const hasPrefix =
          prefix === "00" || this.anagrafica.tel_number.indexOf("+") >= 0;
        return !isNaN(this.anagrafica.tel_number) && !hasPrefix;
      }
      return false;
    },
    validIdOrdine() {
      if (this.anagrafica.id_albo) {
        const regx = /^[A-Za-z]{2}/;
        return this.anagrafica.id_albo.match(regx) ? true : false;
      }
      return null;
    },
    nazioniOptions() {
      if (this.nazioni && this.nazioni.length > 0) {
        return [
          { value: null, text: "Seleziona Nazione" },
          { value: "italia", text: "Italia" },
        ].concat(
          this.nazioni.map((nazione) => {
            return {
              value: nazione.nazione,
              text: nazione.nazione
                .toLowerCase()
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" "),
            };
          })
        );
      } else
        return [
          {
            value: null,
            text: "Seleziona Nazione",
          },
        ];
    },
    ...mapState("utente", ["status", "user"]),
    ...mapState("utility", [
      "searching",
      "comuni",
      "province",
      "regioni",
      "asl",
    ]),
  },
  components: {
    UploadComponent,
  },
  data() {
    return {
      editIndexes: [false, false, false],
      avatarPath: null,
      showCaricaButton: false,
      selected: {
        regione: null,
        provincia: null,
        comune: null,
      },
      anagrafica: {
        regione: null,
        provincia: null,
        comune: null,
        cap: null,
        address: null,
        name: null,
        surname: null,
        cf: null,
        gender: null,
        birthday: null,
        ts_number: null,
        tel_number: null,
        home_number: null,
        tel_number_secondary: null,
        medico_base: null,
        denominazione_asl: null,
        title: null,
        id_albo: null,
        tipo_albo: null,
        nazione_nascita: null,
      },
      titleOptions: ["Sig.", "Sig.ra", "Dott.", "Dott.ssa"],
      tipoAlboOptions: ["Medici", "Dentisti", "Farmacisti", "Infermieri"],
      provinciaAlbo: null,
      idAlbo: null,
      nazioni: [],
      enableBtn: false,
      isPost: true,
      selectingImage: false,
    };
  },
  methods: {
    ...mapActions("utility", [
      "getComuni",
      "getProvince",
      "getRegioni",
      "getAsl",
    ]),
    ...mapActions("utente", ["setAnagrafica", "me"]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    setEditIndex(index, value) {
      this.editIndexes.forEach((el, i) => {
        Vue.set(this.editIndexes, i, false);
      });
      Vue.set(this.editIndexes, index, value);
    },
    setDomicilioData() {
      if (this.selected.comune) {
        this.anagrafica.comune = this.selected.comune.comune;
        this.anagrafica.provincia = this.selected.comune.provincia;
        this.anagrafica.regione = this.selected.comune.regione;
      }
    },
    callDoUploadOnUploadComponent() {
      this.showCaricaButton = false;
      this.$refs.uploadComponentRef.doUpload();
    },
    deleteUserPropic() {
      var self = this;
      cittadinoService
        .deleteUserPropic()
        .then(function (res) {
          self.successAlert("Immagine del profilo eliminata correttamente");
          self.avatarPath = null;
          self.me();
        })
        .catch(function (err) {
          console.log(err);
          self.errorAlert("Errore nell'eliminazione della nota");
        });
    },
    handleUpload(data) {
      const self = this;
      this.avatarPath = data.full_path;
      cittadinoService
        .attachUploadedResourceToUser(data.hashid)
        .then(function (res) {
          self.selectingImage = false;
          self.successAlert("Immagine del profilo caricata correttamente");
          self.me();
        })
        .catch(function (err) {
          console.log(err);
          self.errorAlert("Errore nel caricamento dell'immagine del profilo");
        });
    },
    onSubmit() {
      if (this.anagrafica.tel_number && !this.validNumber) {
        this.errorAlert("Numero di telefono non valido");
        return false;
      }
      this.setAnagrafica({ form: this.anagrafica, isPost: this.isPost }).then(
        (res) => {
          window.scrollTo(0, 0);
          this.editIndexes = [false, false, false];
        }
      );
    },
    setupPrevs() {
      if (this.user.anagrafica.regione) {
        this.selected.regione = this.user.anagrafica.regione;
        this.getProvince(this.selected);
      }
      if (this.user.anagrafica.provincia) {
        this.selected.provincia = this.user.anagrafica.provincia;
        this.getComuni(this.selected);
      }
    },
    findComune(comune) {
      if (this.user.anagrafica && this.user.anagrafica.comune) {
        if (comune.comune === this.user.anagrafica.comune) {
          this.selected.comune = comune;
          return true;
        }
      }
      return false;
    },
    handleAnnulla() {
      this.editIndexes.forEach((el, index) => {
        Vue.set(this.editIndexes, index, false);
      });
      if (this.isPost) {
        $router.go(-1);
      }
      this.me();
    },
    handleFileSelection(file) {
      this.showCaricaButton = true;
      this.selectingImage = true;
      this.avatarPath = URL.createObjectURL(file);
    },
    eliminaAvatarProfilo() {
      if (this.selectingImage) {
        this.avatarPath = null;
        this.showCaricaButton = false;
        return;
      }
      this.$bvModal.show("delete-propic-confirm");
    },
    getNazioni() {
      var self = this;
      utilityService
        .nazioni()
        .then((res) => {
          self.nazioni = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    anagrafica: {
      handler(prev, post) {
        this.enableBtn = true;
      },
      deep: true,
    },
    selected: {
      handler(val) {
        if (val.comune !== null) {
          this.anagrafica.comune = this.selected.comune.comune;
          this.anagrafica.provincia = this.selected.comune.provincia;
          this.anagrafica.regione = this.selected.comune.regione;
        }
      },
      deep: true,
    },
  },
  created() {
    this.me();
    this.getRegioni();
    this.getNazioni();
    this.getAsl({ denominazione: true });
    if (this.user.anagrafica !== null) {
      if (this.user.profile_pic) {
        this.avatarPath = this.user.profile_pic;
      }
      this.isPost = false;
      this.editIndexes = [false, false, false];
      this.anagrafica = this.user.anagrafica;
      this.enableBtn = false;
      this.setupPrevs();
    }
  },
  mounted() {
    if (this.user.anagrafica !== null) {
      this.setupPrevs();
    }
  },
};
</script>
<style lang="scss" scoped>
input.form-control:read-only {
  border-bottom: 1px solid darkgrey;
  border-radius: 0px !important;
  box-shadow: none !important;
  color: $body-color;
}
</style>

<template>
  <div style="min-height: 90vh" class="bg-white">
    <h2 class="mt-4 py-4">Pagina non trovata</h2>
    <b-row cols="8">
      <b-col></b-col>
      <b-col></b-col>
      <b-col></b-col>
      <b-col class="bg-white">
        <img src="@/assets/common/404_6.gif" />
        <b-button @click="$router.go(-1)">Indietro</b-button>
      </b-col>
      <b-col></b-col>
      <b-col></b-col>
      <b-col></b-col>
    </b-row>
    <p></p>
  </div>
</template>
<script>
export default {
  name: "NotFoundPage",
  data() {
    return {};
  },
};
</script>

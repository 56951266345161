export default {
  bind(el) {
    el.capitalizeFirstHandler = function (event) {
      let value = event.target.value
      if (typeof value === "string" && value.length > 0) {
        event.target.value = value.charAt(0).toUpperCase() + value.slice(1)
      }
    }
    el.addEventListener("input", el.capitalizeFirstHandler)
  },
  unbind(el) {
    el.removeEventListener("input", el.capitalizeFirstHandler)
    delete el.capitalizeFirstHandler
  }
}

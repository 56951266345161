<template>
  <span>
    <b-modal
      ref="expired-session"
      id="expired-session"
      hide-footer
      size="xl"
      no-close-on-backdrop
      no-close-on-esc
      centered
      @hidden="triggerLogout()"
      class="black-modal"
    >
      <b-row class="justify-content-center">
        <b-col class="col-12 d-flex justify-content-center mb-5">
          <img
            :src="require(`@/assets/${$root.scope}/logo.svg`)"
            alt="appLogo"
            class="px-lg-2"
            style="max-width: 200px"
            key="icon_logo"
          />
        </b-col>
        <b-col class="col-12 d-flex justify-content-center mb-5">
          <h2>Sessione Scaduta</h2>
        </b-col>
        <b-col class="col-12 d-flex justify-content-center">
          <b-button variant="popup-button" class="mt-3" @click="triggerLogout()"
            >Accedi</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<style>
#expired-session.modal {
  background-color: black;
}
</style>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    userSessionTimeout: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      userActivityThrottlerTimeout: null,
      userActivityTimeout: null,
      timerExpired: false,
    };
  },
  methods: {
    ...mapActions("utente", ["logout"]),
    activateActivityTracker() {
      window.addEventListener("mousemove", this.userActivityThrottler);
      window.addEventListener("scroll", this.userActivityThrottler);
      window.addEventListener("keydown", this.userActivityThrottler);
      window.addEventListener("resize", this.userActivityThrottler);
    },
    resetUserActivityTimeout() {
      clearTimeout(this.userActivityTimeout);
      this.userActivityTimeout = setTimeout(() => {
        this.inactiveUserAction();
      }, this.userSessionTimeout * 60 * 1000);
    },
    userActivityThrottler() {
      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();

          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, 1000);
      }
    },
    triggerLogout() {
      this.logout();
    },
    inactiveUserAction() {
      this.$refs["expired-session"].show();
      localStorage.setItem("sessionExpired", true);
    },
  },
  beforeMount() {
    this.activateActivityTracker();
    this.resetUserActivityTimeout();
    if (localStorage.getItem("sessionExpired")) {
      this.logout();
    }
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.userActivityThrottler);
    window.removeEventListener("scroll", this.userActivityThrottler);
    window.removeEventListener("keydown", this.userActivityThrottler);
    window.removeEventListener("resize", this.userActivityThrottler);

    clearTimeout(this.userActivityTimeout);
    clearTimeout(this.userActivityThrottlerTimeout);
  },
};
</script>

import config from "../_config/config"
import { authHeader, postHeader } from "../_helpers"
import axios from "axios"

axios.defaults.baseURL = config.apiUrl
axios.defaults.headers.post["Content-Type"] = "application/json"
axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest"
axios.defaults.headers.put["Content-Type"] = "application/json"
axios.defaults.headers.put["X-Requested-With"] = "XMLHttpRequest"

export const projectService = {
  getOwnProjects,
  getProjectPosts,
  getProjectInfo,
  storeNewPost,
  updatePost,
  deletePost
}

function getOwnProjects(filters = null) {
  const sett = { headers: authHeader(), params: filters }
  return axios
    .get("users/own/projects", sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getProjectPosts(projectId, filters = null) {
  const sett = { headers: authHeader(), params: filters }
  const endpoint = "projects/" + projectId + "/posts"
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getProjectInfo(projectId, filters = null) {
  const sett = { headers: authHeader(), params: filters }
  const endpoint = "projects/" + projectId
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function storeNewPost(data) {
  const sett = { headers: authHeader() }
  const endpoint = "projects/posts"
  return axios
    .post(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function updatePost(data) {
  const sett = { headers: authHeader() }
  const endpoint = "projects/posts/" + data.hashid
  return axios
    .put(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function deletePost(postId) {
  const sett = { headers: authHeader() }
  const endpoint = "projects/posts/" + postId
  return axios
    .delete(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

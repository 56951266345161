<template>
  <div class="register pb-4">
    <!-- <b-col class="col-12 align-content-center py-2 py-md-3 py-xl-4">
      <div class="mx-auto col-10 col-md-4 col-xl-3 py-2">
        <b-img
          :src="require(`@/assets/${$root.scope}/logo_area_pubblica.png`)"
          fluid-grow
          alt="logo app"
          style="max-height: 30vh"
        ></b-img>
      </div>
    </b-col> -->
    <b-row cols="1" class="justify-content-center align-items-center my-4">
      <b-col class="col-12 text-center">
        <b-col class="col-6 col-sm-4 col-md-3 col-lg-2 mx-auto">
          <b-img
            :src="require(`@/assets/${$root.scope}/logo_area_pubblica.png`)"
            alt="logo app"
            fluid-grow
          ></b-img>
        </b-col>
      </b-col>
    </b-row>
    <h2 class="mt-md-3 mb-md-4">Registrazione</h2>
    <div class="mt-md-4">
      <b-card
        no-body
        class="col-lg-10 col-sm-12 mx-auto border-0 p-md-2 p-lg-4"
      >
        <b-tabs
          no-key-nav
          v-model="tab_index"
          class="bg-white"
          nav-class="border-0 mb-4"
        >
          <b-tab
            title-link-class="bg-white border-0 tab__no__hover"
            active-tab-class="bg-white border-0 tab__no__hover"
            title-item-class="bg-white border-0 tab__no__hover"
            :title="'Step ' + (tab_index + 1) + ' di 3'"
          >
            <b-row cols="2" class="mt-n2 px-md-4 pt-2">
              <b-col
                class="my-2 order-1 order-sm-1 d-flex justify-content-center align-items-start"
              >
                <div
                  class="registration-type-tile"
                  @click="form.type = 0"
                  :class="
                    form.type === 0
                      ? 'bg-secondary text-white'
                      : 'bg-grey text-dark-grey'
                  "
                >
                  Sono un cittadino
                </div>
              </b-col>
              <b-col
                class="my-2 order-3 order-sm-2 d-flex justify-content-center align-items-start"
              >
                <div
                  class="registration-type-tile"
                  @click="form.type = 1"
                  :class="
                    form.type === 1
                      ? 'bg-secondary text-white'
                      : 'bg-grey text-dark-grey'
                  "
                >
                  Sono un operatore sanitario
                </div>
              </b-col>
              <b-col
                class="mt-4 order-2 order-sm-3 d-flex justify-content-center align-items-start"
              >
                <p class="text-justify">
                  Accedi alla tua cartella clinica, registra i tuoi familiari,
                  mettiti in contatto con il tuo Medico o Farmacista, e molto
                  altro.
                </p>
              </b-col>
              <b-col
                class="mt-4 order-4 order-sm-4 d-flex justify-content-center align-items-start"
              >
                <p class="text-justify">
                  Mettiti in contatto con tuoi pazienti e organizza i tuoi
                  Appuntamenti e tele consulti in tutta sicurezza.
                </p>
              </b-col>
            </b-row>
            <br />
            <div class="col-12 text-right mt-3 pb-2">
              <b-button
                type="button"
                variant="outline-secondary mx-2"
                @click="$router.push('/login')"
                >Indietro</b-button
              >
              <b-button
                type="button"
                variant="secondary text-white mx-2"
                :disabled="form.type == null"
                @click="tab_index++"
                >Avanti
                <b-icon icon="arrow-right"></b-icon>
              </b-button>
            </div>
            <p>
              <span class="text-dark">
                <a
                  target="_blank"
                  class="text-underlined point"
                  href="https://concura.online/wp-content/uploads/2024/07/PP.pdf"
                >
                  <u>Consulta Informativa Privacy</u></a
                >
              </span>
            </p>
          </b-tab>
          <b-tab
            title-link-class="bg-white border-0 tab__no__hover"
            active-tab-class="bg-white border-0 tab__no__hover"
            title-item-class="bg-white border-0 tab__no__hover"
          >
            <p
              class="text-right text-secondary text-center mb-5"
              v-if="$root.scope == 'concura'"
            >
              Se hai bisogno di assistenza, chiamaci al:
              <span
                class="font-weight-bolder point underlined"
                @click="callSupport"
                >800770272</span
              >
            </p>
            <b-form @submit.prevent="tab_index++">
              <div v-if="form.type === 1" class="text-left fs-6 mx-auto col-12">
                <b-col>
                  <label for="codice_azienda">Codice azienda</label>
                  <b-icon
                    class="ml-2"
                    icon="question-circle"
                    variant="secondary"
                    id="company-code-explain"
                  ></b-icon>
                </b-col>

                <b-tooltip
                  :placement="$root.isMobile ? 'bottom' : 'rightbottom'"
                  target="company-code-explain"
                  :triggers="$root.isMobile ? 'click' : 'hover'"
                >
                  Il codice azienda si trova nella sezione impostazioni
                  dell'operatore amministratore di struttura
                </b-tooltip>
              </div>
              <b-col>
                <b-form-group
                  v-if="form.type === 1"
                  class="text-left mb-3 mx-auto col-12"
                  description="Inserisci il codice di 10 cifre fornito dalla tua azienda."
                >
                  <b-form-input
                    v-model="form.company_code"
                    minlength="10"
                    placeholder="Codice azienda..."
                    class="my-3"
                    name="codice_azienda"
                    :state="form.company_code == null ? null : canSubmit"
                    required
                    v-on:input="checkCode"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <!-- <div class="mx-auto"> -->
              <div class="col-12 d-flex flex-wrap">
                <b-col class="col-12 col-sm-6 d-flex flex-wrap">
                  <b-form-group
                    label="Titolo"
                    label-for="title"
                    class="text-left col-12 col-sm-4 pr-sm-1 mx-auto px-0"
                    :class="$root.isMobile ? '' : 'col'"
                  >
                    <b-form-select
                      v-capitalize-first-select
                      class="mb-3 mt-2 custom-select text-capitalize"
                      name="title"
                      v-model="anagrafica.title"
                      :options="titleOptions"
                    >
                      <b-form-select-option :value="null" disabled
                        >Titolo</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>
                  <b-form-group
                    label="Nome*"
                    label-for="name"
                    class="text-left col-12 col-sm-8 pl-sm-1 mx-auto px-0"
                    :class="$root.isMobile ? '' : 'col'"
                  >
                    <b-form-input
                      v-capitalize-first
                      class="my-3 text-capitalize"
                      name="name"
                      type="text"
                      placeholder="Nome..."
                      v-model="anagrafica.name"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-form-group
                  label="Cognome*"
                  label-for="surname"
                  class="text-left col-12 col-sm-6 mx-auto"
                  :class="$root.isMobile ? '' : 'col'"
                >
                  <b-form-input
                    v-capitalize-first
                    class="my-3 text-capitalize"
                    name="surname"
                    type="text"
                    placeholder="Cognome..."
                    v-model="anagrafica.surname"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <template v-if="form.type === 0">
                <div class="col-12 d-flex flex-wrap">
                  <b-form-group
                    label="Codice fiscale*"
                    label-for="cf"
                    class="text-left col-12 col-sm-6 mx-auto"
                    :class="$root.isMobile ? '' : 'col'"
                  >
                    <b-form-input
                      class="my-3 text-uppercase"
                      name="cf"
                      type="text"
                      placeholder="Codice fiscale..."
                      maxlength="16"
                      minlength="16"
                      v-model="anagrafica.cf"
                      :state="anagrafica.cf == null ? null : validCf"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Numero Tessera Sanitaria"
                    label-for="ts_number"
                    class="text-left col-12 col-sm-6 mx-auto"
                    :class="$root.isMobile ? '' : 'col'"
                  >
                    <b-form-input
                      class="my-3"
                      name="ts_number"
                      type="text"
                      placeholder="Numero tessera..."
                      v-model="anagrafica.ts_number"
                      maxlength="20"
                      minlength="20"
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-12 d-flex flex-wrap">
                  <b-form-group
                    label="Data di nascita*"
                    label-for="birthday"
                    class="text-left col-12 col-sm-6"
                    :class="$root.isMobile ? '' : 'col'"
                  >
                    <b-form-input
                      class="my-3"
                      name="birthday"
                      type="date"
                      v-model="anagrafica.birthday"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Genere"
                    label-for="gender"
                    class="text-left col-12 col-sm-6"
                  >
                    <b-form-radio-group
                      v-model="anagrafica.gender"
                      name="gender-options"
                      class="d-flex pt-0 pb-2 pb-md-0 pt-sm-3"
                    >
                      <b-form-radio value="maschile" class="col-md-3"
                        >uomo</b-form-radio
                      >
                      <b-form-radio value="femminile" class="col-md-3"
                        >donna</b-form-radio
                      >
                    </b-form-radio-group>
                  </b-form-group>
                </div>
                <div class="col-12 d-flex flex-wrap">
                  <b-form-group
                    label="Nazione di nascita*"
                    label-for="nazione_di_nascita"
                    class="text-left col-12 col-sm-6"
                  >
                    <b-form-select
                      v-capitalize-first-select
                      class="my-3 text-capitalize"
                      name="nazione_nascita"
                      v-model="anagrafica.nazione_nascita"
                      required
                    >
                      <b-form-select-option :value="null" key="nocount"
                        >Seleziona Nazione</b-form-select-option
                      >
                      <b-form-select-option value="italia" key="italia"
                        >italia</b-form-select-option
                      >
                      <b-form-select-option
                        v-for="(nazione, ind) in nazioni"
                        :value="nazione.nazione"
                        :key="ind + '_' + nazione.nazione"
                        >{{ nazione.nazione }}</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>
                  <b-form-group
                    label="Regione di residenza*"
                    label-for="regione"
                    class="text-left col-12 col-sm-6"
                  >
                    <b-form-select
                      v-capitalize-first-select
                      class="my-3 text-capitalize"
                      name="regione"
                      v-model="selected.regione"
                      @change="getProvince(selected)"
                      required
                    >
                      <b-form-select-option :value="null" key="noreg"
                        >Seleziona Regione</b-form-select-option
                      >
                      <b-form-select-option
                        v-for="regione in regioni"
                        :value="regione"
                        :key="regione"
                        >{{ regione }}</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>

                  <b-form-group
                    label="Provincia di residenza*"
                    label-for="provincia"
                    class="text-left col-12 col-sm-6 mx-auto"
                  >
                    <b-form-select
                      v-capitalize-first-select
                      class="my-3 text-capitalize"
                      name="provincia"
                      v-model="selected.provincia"
                      @change="getComuni(selected)"
                      required
                    >
                      <b-form-select-option :value="null"
                        >Seleziona Provincia</b-form-select-option
                      >
                      <b-form-select-option
                        v-for="provincia in province"
                        :value="provincia"
                        :key="provincia"
                        >{{ provincia }}</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>

                  <b-form-group
                    label="Comune di residenza*"
                    label-for="comune"
                    class="text-left col-12 col-sm-6 mx-auto"
                  >
                    <b-form-select
                      v-capitalize-first-select
                      class="my-3 text-capitalize"
                      name="comune"
                      v-model="selected.comune"
                      :key="
                        comuni && comuni.length
                          ? comuni[0].comune + comuni.length
                          : null
                      "
                      required
                    >
                      <b-form-select-option
                        v-for="(com, ind) in comuni"
                        :key="ind + '_' + com.comune"
                        :value="com"
                        @change="setDomicilioData"
                        :selected="findComune(com)"
                        >{{ com.comune }}</b-form-select-option
                      >
                      <b-form-select-option :value="null"
                        >Seleziona Comune</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>
                  <!-- </div>
              <div class="col-12 d-flex flex-wrap"> -->
                  <b-form-group
                    label="Indirizzo e numero civico*"
                    label-for="address"
                    class="text-left col-12 col-sm-6 mx-auto"
                  >
                    <b-form-input
                      v-capitalize-first
                      class="my-3"
                      name="address"
                      type="text"
                      required
                      placeholder="Indirizzo..."
                      v-model="anagrafica.address"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="CAP*"
                    label-for="cap"
                    class="text-left col-12 col-sm-6 mr-auto"
                  >
                    <b-form-input
                      class="my-3"
                      name="cap"
                      type="text"
                      required
                      placeholder="CAP..."
                      v-model="anagrafica.cap"
                      minlength="5"
                      maxlength="5"
                    ></b-form-input>
                  </b-form-group>
                  <!-- telefono domicilio -->
                  <b-form-group
                    label="Telefono Domicilio"
                    label-for="home_number"
                    class="text-left col-12 col-sm-6 mr-auto"
                  >
                    <b-form-input
                      class="my-3"
                      name="cap"
                      type="text"
                      placeholder="Telefono domicilio..."
                      v-model="anagrafica.home_number"
                      minlength="9"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="projects"
                    label="Aderisco al progetto*"
                    label-for="project"
                    class="text-left col-12 col-sm-6"
                  >
                    <b-form-select
                      class="mb-3 mt-2 text-capitalize"
                      name="project"
                      v-model="form.project"
                      required
                    >
                      <b-form-select-option :value="null" disabled>
                        --
                      </b-form-select-option>
                      <b-form-select-option
                        v-for="project in projects"
                        :value="project.hashid"
                        :key="project.hashid"
                        >{{ project.name }}</b-form-select-option
                      >
                      <b-form-select-option value="nessuno"
                        >Nessun progetto</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>
                </div>
              </template>
              <div class="col-12 d-flex flex-wrap">
                <template v-if="form.type === 1">
                  <b-form-group
                    label="Ordine"
                    label-for="tipo_albo"
                    class="text-left col-12 col-sm-6"
                  >
                    <b-form-select
                      v-capitalize-first-select
                      class="mb-3 mt-2 custom-select text-capitalize"
                      name="tipo_albo"
                      v-model="anagrafica.tipo_albo"
                      :options="tipoAlboOptions"
                    >
                    </b-form-select>
                  </b-form-group>
                  <b-form-group
                    label="
                      ID Iscrizione Albo**
                    "
                    label-for="id_albo"
                    class="text-left col-12 col-sm-6"
                    :description="'**Includere la sigla provincia'"
                  >
                    <b-form-input
                      class="my-3 text-capitalize"
                      name="id_albo"
                      type="text"
                      placeholder="ID ordine..."
                      v-model="anagrafica.id_albo"
                      :state="validIdOrdine"
                      @input="
                        (val) =>
                          (anagrafica.id_albo =
                            anagrafica.id_albo.toUpperCase())
                      "
                    ></b-form-input>
                  </b-form-group>
                </template>
                <b-form-group
                  label="Email*"
                  label-for="email"
                  class="text-left pt-2 col-12 col-sm-6"
                >
                  <b-form-input
                    v-model="form.email"
                    placeholder="Email..."
                    class="my-3"
                    name="email"
                    autocomplete="username"
                    :state="form.email == null ? null : validEmail"
                    :disabled="!anagrafica.name || !anagrafica.surname"
                    required
                  ></b-form-input>
                </b-form-group>
                <!-- </div>
              <div class="col-12 d-flex flex-wrap"> -->
                <!-- <b-form-group
                  label="Numero di telefono*"
                  label-for="tel_number"
                  class="text-left col-12 pt-2 pt-sm-0 col-sm-6 d-flex flex-column"
                >
                  <b-form-input
                    class="my-3"
                    name="tel_number"
                    type="text"
                    placeholder="telefono..."
                    v-model="anagrafica.tel_number"
                    minlength="9"
                    :state="anagrafica.tel_number !== null ? validNumber : null"
                    required
                  ></b-form-input>
                </b-form-group> -->
                <b-form-group
                  label="Numero di telefono*"
                  label-for="tel_number"
                  class="text-left col-12 pt-2 pt-sm-0 col-sm-6 d-flex flex-column"
                >
                  <b-input-group class="">
                    <b-input-group-prepend>
                      <h6 class="mt-4 pt-2 pr-1 text-prim-grad-1">+39</h6>
                    </b-input-group-prepend>
                    <b-form-input
                      class="my-3"
                      name="tel_number"
                      type="text"
                      placeholder="Telefono..."
                      v-model="anagrafica.tel_number"
                      minlength="9"
                      required
                      :state="
                        anagrafica.tel_number !== null ? validNumber : null
                      "
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </div>
              <div class="col-12 d-flex flex-wrap">
                <b-form-group
                  label="Password*"
                  label-for="password"
                  class="text-left col-12 col-sm-6 mx-auto"
                >
                  <b-form-input
                    class="my-3"
                    name="password"
                    :type="passwordClear ? 'text' : 'password'"
                    v-model="form.password"
                    placeholder="Password..."
                    autocomplete="new-password"
                    :state="form.password == null ? null : validatePassword"
                    required
                  >
                  </b-form-input>
                  <b-icon
                    :icon="passwordClear ? 'eye' : 'eye-slash'"
                    font-scale="1.3"
                    class="eye_icon point"
                    @click="passwordClear = !passwordClear"
                  ></b-icon>
                </b-form-group>
                <b-form-group
                  label="Conferma password*"
                  label-for="password"
                  class="text-left col-12 col-sm-6 mx-auto"
                  :class="$root.isMobile ? '' : 'col'"
                >
                  <b-form-input
                    class="my-3"
                    name="password_confirmation"
                    :type="passwordConfirmClear ? 'text' : 'password'"
                    v-model="form.password_confirmation"
                    placeholder="Conferma password..."
                    :state="form.password == null ? null : validateConfirm"
                    required
                  ></b-form-input>

                  <b-icon
                    :icon="passwordConfirmClear ? 'eye' : 'eye-slash'"
                    font-scale="1.3"
                    class="eye_icon point"
                    @click="passwordConfirmClear = !passwordConfirmClear"
                  ></b-icon>
                </b-form-group>
                <p class="text-left text-medium-grey col fs-6">
                  La password non può includere il nome né il cognome e deve
                  contenere almeno 8 caratteri, di cui almeno un numero, una
                  maiuscola, una minuscola ed un
                  <span
                    v-b-tooltip
                    title="Devi usare almeno un carattere tra ! @ # $ % ^ & * ) ( + = . _ -"
                    >carattere speciale</span
                  >.
                </p>
              </div>
              <p
                v-if="form.project && form.project !== 'nessuno'"
                class="text-left text-medium-grey col-12 pl-4 ml-1"
              >
                **Selezionando il progetto
                {{ findProjectName(form.project).name }}, la struttura sanitaria
                coinvolta nel progetto verrà inserita nel tuo circolo di cura
              </p>
              <br />
              <div
                class="col-12 text-right pb-2 d-flex justify-content-between flex-wrap"
              >
                <b-button
                  type="button"
                  variant="outline-secondary col-12 col-sm-4 col-md-3 my-2  my-sm-0  col-xl-2 mx-2"
                  @click="tab_index--"
                  >Indietro</b-button
                >
                <b-button
                  type="submit"
                  variant="secondary text-white col-12 col-sm-4 col-md-3 my-2 my-sm-0  col-xl-2 mx-2"
                  :disabled="!enableSecondBtn"
                  >Avanti
                  <b-icon icon="arrow-right"></b-icon>
                </b-button>
              </div>
              <p>
                <span class="text-dark">
                  <a
                    target="_blank"
                    class="text-underlined point"
                    href="https://concura.online/wp-content/uploads/2024/07/PP.pdf"
                  >
                    <u>Consulta Informativa Privacy</u></a
                  >
                </span>
              </p>
            </b-form>
          </b-tab>
          <b-tab
            no-nav-style
            title-link-class="bg-white border-0 tab__no__hover"
            active-tab-class="bg-white border-0 tab__no__hover"
            title-item-class="bg-white border-0 tab__no__hover"
          >
            <!-- <template v-if="form.type === 0"> -->
            <div class="col-12">
              <h6 class="text-left pb-1">
                Termini e Condizioni e Informativa Privacy
              </h6>
              <p class="text-justify informativa__p">
                Per concludere la registrazione è necessario prendere visione ed
                accettare
                <a
                  target="_blank"
                  class="text-underlined point"
                  :href="infoLinks.paziente"
                >
                  <u>condizioni generali di contratto</u></a
                >
                e
                <a
                  target="_blank"
                  class="text-underlined point"
                  href="https://concura.online/wp-content/uploads/2024/07/PP.pdf"
                >
                  <u>Informativa Privacy</u></a
                >
              </p>
              <b-row cols="1" class="text-left">
                <b-form-checkbox
                  class="text-font-light-grey"
                  name="charge_consense"
                  :value="true"
                  :unchecked-value="false"
                  v-model="form.charge_consense"
                  :state="form.charge_consense == true"
                >
                  <span class="text-dark"
                    >Ho letto e accetto la
                    <a
                      target="_blank"
                      class="text-underlined point"
                      href="https://concura.online/wp-content/uploads/2024/07/PP.pdf"
                    >
                      <u>Informativa Privacy</u></a
                    >
                  </span>
                </b-form-checkbox>
                <b-form-checkbox
                  class="text-font-light-grey"
                  name="charge_consense"
                  :value="false"
                  :unchecked-value="false"
                  v-model="form.charge_consense"
                  :state="false"
                >
                  Non accetto
                </b-form-checkbox>
              </b-row>
              <p class="text-justify informativa__p my-4"></p>
              <b-row cols="1" class="text-left">
                <b-form-checkbox
                  class="text-font-light-grey"
                  name="data_consense"
                  :value="true"
                  :unchecked-value="false"
                  v-model="form.data_consense"
                  :state="form.data_consense == true"
                >
                  <span class="text-dark"
                    >Ho letto e accetto
                    <a
                      target="_blank"
                      class="text-underlined point"
                      :href="infoLinks.paziente"
                    >
                      <u>Termini e Condizioni d'uso del servizio</u></a
                    >
                  </span>
                </b-form-checkbox>
                <b-form-checkbox
                  class="text-font-light-grey"
                  name="data_consense"
                  :value="false"
                  :unchecked-value="false"
                  v-model="form.data_consense"
                  :state="false"
                >
                  Non accetto
                </b-form-checkbox>
              </b-row>
              <p class="text-justify informativa__p my-4">e</p>
              <b-row cols="1" class="text-left">
                <b-form-checkbox
                  class="text-font-light-grey"
                  name="share_consense"
                  :value="true"
                  :unchecked-value="false"
                  v-model="form.share_consense"
                  :state="form.share_consense == true"
                >
                  <span class="text-dark"
                    >Ai sensi dell’articolo 1341 e ss c.c. accetto espressamente
                    le previsioni di cui agli articoli 2, 7, 8, 10, 12 dei
                    presenti
                    <a
                      target="_blank"
                      class="text-underlined point"
                      :href="infoLinks.paziente"
                    >
                      <u>Termini e Condizioni d'uso</u></a
                    >
                  </span>
                </b-form-checkbox>
                <b-form-checkbox
                  class="text-font-light-grey"
                  name="share_consense"
                  :value="false"
                  :unchecked-value="false"
                  v-model="form.share_consense"
                  :state="false"
                >
                  Non accetto
                </b-form-checkbox>
              </b-row>

              <h6 class="text-left pt-4 pb-1">
                Autorizzazione alle comunicazioni commerciali
              </h6>
              <b-row cols="1" class="text-left">
                <b-form-checkbox
                  class="text-font-light-grey"
                  name="marketing_consense"
                  :value="true"
                  :unchecked-value="false"
                  v-model="form.marketing_consense"
                  :state="form.marketing_consense == true"
                >
                  <span class="text-dark"
                    >Acconsento alla ricezione di comunicazioni commerciali per
                    finalità promozionali come da
                    <a
                      target="_blank"
                      class="text-underlined point"
                      href="https://concura.online/wp-content/uploads/2024/07/PP.pdf"
                    >
                      <u>Informativa Privacy</u></a
                    >
                  </span>
                </b-form-checkbox>
                <b-form-checkbox
                  class="text-font-light-grey"
                  name="marketing_consense"
                  :value="false"
                  :unchecked-value="false"
                  v-model="form.marketing_consense"
                  :state="false"
                >
                  Non accetto
                </b-form-checkbox>
              </b-row>
            </div>
            <br />
            <div class="col-12 text-right mt-3 pb-2">
              <b-button
                type="button"
                variant="outline-secondary mx-2"
                @click="tab_index--"
                >Indietro</b-button
              >
              <b-button
                type="button"
                variant="secondary text-white mx-2"
                :disabled="!enableThirdBtn"
                @click.prevent="OnSubmit"
              >
                Completa
              </b-button>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

import { mapState, mapActions } from "vuex";
import * as moment from "moment/moment";
import { utilityService } from "@/_services";

export default {
  name: "RegisterPage",
  components: {
    // HelloWorld,
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
        password_confirmation: null,
        data_consense: false,
        share_consense: false,
        notification_consense: true,
        charge_consense: false,
        marketing_consense: true,
        type: null,
        project: null,
        // company_code: null,
        anagrafica: null,
      },
      anagrafica: {
        regione: null,
        provincia: null,
        comune: null,
        cap: null,
        address: null,
        name: null,
        surname: null,
        cf: null,
        gender: null,
        birthday: null,
        ts_number: null,
        tel_number: null,
        title: null,
        id_albo: null,
        tipo_albo: null,
        nazione_nascita: "italia",
      },
      titleOptions: ["Sig.", "Sig.ra", "Dott.", "Dott.ssa"],
      tipoAlboOptions: ["Medici", "Dentisti", "Farmacisti", "Infermieri"],
      nazioni: [],
      has_minimum_lenth: true,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      has_name: false,
      has_surname: false,
      tab_index: 0,
      canSubmit: false,
      selected: {
        regione: null,
        provincia: null,
        comune: null,
      },
      cfMonths: {
        A: "01",
        B: "02",
        C: "03",
        D: "04",
        E: "05",
        H: "06",
        L: "07",
        M: "08",
        P: "09",
        R: "10",
        S: "11",
        T: "12",
        a: "01",
        b: "02",
        c: "03",
        d: "04",
        e: "05",
        h: "06",
        l: "07",
        m: "08",
        p: "09",
        r: "10",
        s: "11",
        t: "12",
      },
      infoLinks: {
        // paziente: process.env.VUE_APP_LINK_INFO_REGISTRAZIONE_PAZIENTE,
        // operatore: process.env.VUE_APP_LINK_INFO_REGISTRAZIONE_OPERATORE,
        // paziente: "/assets/docs/TeC.pdf",
        // operatore: "/assets/docs/TeC.pdf",
        paziente: "https://concura.online/wp-content/uploads/2024/07/TC.pdf",
        operatore: "https://concura.online/wp-content/uploads/2024/07/TC.pdf",
      },
      passwordClear: false,
      passwordConfirmClear: false,
    };
  },
  watch: {
    selected: {
      handler(val) {
        if (val.comune !== null) {
          this.anagrafica.comune = this.selected.comune.comune;
          this.anagrafica.provincia = this.selected.comune.provincia;
          this.anagrafica.regione = this.selected.comune.regione;
        }
      },
      deep: true,
    },
    tab_index: function (val) {
      if (val == 2) {
        window.scrollTo(0, 0);
      }
    },
  },
  computed: {
    validatePassword() {
      if (!this.anagrafica.name || !this.anagrafica.surname) {
        return null;
      }
      if (this.form.password) {
        this.has_minimum_lenth = this.form.password.length > 8;
        this.has_number = /\d/.test(this.form.password);
        this.has_lowercase = /[a-z]/.test(this.form.password);
        this.has_uppercase = /[A-Z]/.test(this.form.password);
        this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.form.password);
        const regName = new RegExp(this.anagrafica.name.toLowerCase());
        const regSurname = new RegExp(this.anagrafica.surname.toLowerCase());
        this.has_name = !regName.test(this.form.password.toLowerCase());
        this.has_surname = !regSurname.test(this.form.password.toLowerCase());
        return (
          this.has_minimum_lenth &&
          this.has_number &&
          this.has_lowercase &&
          this.has_uppercase &&
          this.has_name &&
          this.has_surname &&
          this.has_special
        );
      }
      return false;
    },
    validCf() {
      var validRegex =
        /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;
      if (this.anagrafica.cf.length === 16) {
        return this.anagrafica.cf.match(validRegex) ? this.fillOthers() : false;
      }
      return this.anagrafica.cf.match(validRegex) ? true : false;
    },
    validNumber() {
      if (this.anagrafica.tel_number) {
        const prefix = this.anagrafica.tel_number.slice(0, 2);
        const hasPrefix =
          prefix === "00" || this.anagrafica.tel_number.indexOf("+") >= 0;
        return !isNaN(this.anagrafica.tel_number) && !hasPrefix;
      }
      return false;
    },
    validateConfirm() {
      if (this.form.password) {
        return this.form.password_confirmation === this.form.password;
      }
      return false;
    },
    validIdOrdine() {
      if (this.anagrafica.id_albo) {
        const regx = /^[A-Za-z]{2}/;
        return this.anagrafica.id_albo.match(regx) ? true : false;
      }
      return null;
    },
    validEmail() {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return this.form.email.match(validRegex) ? true : false;
    },
    enableSecondBtn() {
      if (this.form.type !== null) {
        if (this.validatePassword && this.validateConfirm && this.validNumber) {
          if (this.form.type == 0) {
            if (
              this.validCf &&
              this.form.project !== null &&
              this.anagrafica.address &&
              this.anagrafica.cap
            )
              return true;
            else return false;
          } else return true;
        }
        return false;
      }
      return false;
    },
    enableThirdBtn() {
      if (
        this.form.data_consense &&
        this.form.share_consense &&
        this.form.charge_consense
      ) {
        // if (this.form.type == 0 && !this.form.charge_consense) {
        //   return false;
        // }
        return true;
      }
      return false;
    },
    ...mapState("utility", [
      "searching",
      "comuni",
      "province",
      "regioni",
      "projects",
    ]),
  },
  methods: {
    ...mapActions("utente", ["register"]),
    ...mapActions("utility", [
      "getComuni",
      "getProvince",
      "getRegioni",
      "getProjects",
    ]),
    OnSubmit() {
      if (this.form.type === 1) {
        this.form.charge_consense = undefined;
      } else {
        // this.form.company_code = undefined;
      }
      // console.log(this.form);
      if (this.form) {
        if (this.form.project && this.form.project === "nessuno") {
          this.form.project = null;
        }
        this.form.anagrafica = this.anagrafica;
        this.register(this.form)
          .then((res) => {
            window.scrollTo(0, 0);
          })
          .catch((error) => {
            console.log("after login error", error);
          });
      }
    },
    checkCode() {
      // console.log(this.form.company_code);
      // console.log(this.form.company_code.length);
      if (this.form.company_code !== null) {
        this.canSubmit = this.form.company_code.length >= 10;
      }
    },
    setDomicilioData() {
      // console.log("on change", this.selected.comune);
      if (this.selected.comune) {
        this.anagrafica.comune = this.selected.comune.comune;
        this.anagrafica.provincia = this.selected.comune.provincia;
        this.anagrafica.regione = this.selected.comune.regione;
      }
    },
    findComune(comune) {
      if (this.anagrafica.comune) {
        if (comune.comune === this.anagrafica.comune) {
          this.selected.comune = comune;
          return true;
        }
      }
      return false;
    },
    fillOthers() {
      // console.log("fillOthers");
      try {
        var cf = this.anagrafica.cf;
        let getYear = cf.substr(6, 2);
        let getMonth = this.cfMonths[cf.substr(8, 1)];
        let getDay = parseInt(cf.substr(9, 2));
        let day = getDay > 31 ? getDay - 40 : getDay;
        this.anagrafica.gender = getDay > 31 ? "femminile" : "maschile";
        // console.log(parseInt(getYear));
        let currentYear = moment().year() - 2000;
        let yearPrefix =
          parseInt(getYear) >= 0 && parseInt(getYear) <= currentYear
            ? "20"
            : "19";
        var stringBDate = yearPrefix + getYear + "/" + getMonth + "/" + day;
        var isDateValid = moment(stringBDate).isValid();
        // console.log(isDateValid);
        this.anagrafica.birthday = moment(stringBDate).format("yyyy-MM-DD");
        //un codice fiscale è estero se z è il carattere in 12sima posizione
        if (cf.substr(11, 1) === "Z") {
          this.anagrafica.nazione_nascita = this.nazioni.find(
            (nazione) => nazione.codice === cf.substr(11, 4)
          )?.nazione;
        } else {
          this.anagrafica.nazione_nascita = "italia";
          this.searchComuneViaCCatastale(cf.substr(11, 4));
        }

        return isDateValid;
      } catch (err) {
        // console.log("fillOthers", err);
        return false;
      }
    },
    searchComuneViaCCatastale(codice) {
      const self = this;
      utilityService
        .searchComuneViaCodiceCatastale(codice)
        .then(function (res) {
          // console.log(res.data.data)
          if (res.data.data) {
            self.selected.regione = res.data.data.regione;
            self.getProvince(res.data.data);
            self.selected.provincia = res.data.data.provincia;
            self.getComuni(res.data.data);
            self.selected.comune = res.data.data;
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    getNazioni() {
      var self = this;
      utilityService
        .nazioni()
        .then((res) => {
          self.nazioni = res.data;
          // console.log(res.data)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    findProjectName(hashid) {
      // Object.keys();this.project
      return this.projects.find((el) => el.hashid === hashid);
    },
    callSupport() {
      window.location.href = "tel:800770272";
    },
  },
  created() {
    this.getRegioni();
    this.getNazioni();
    this.getProjects({ active: true, public: true });
    if (this.$route.query.type && this.$route.query.type === "cittadino") {
      this.form.type = 0;
      this.tab_index++;
    }
  },
};
</script>
<style lang="scss" scoped>
.registration-type-tile {
  padding: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0.85rem;
}
.eye_icon {
  position: absolute;
  right: 53px;
  top: 3.4rem;
  color: #9c9c9c;
}
@media screen and (max-width: 767px) {
  .registration-type-tile {
    padding: 0.8rem;
    height: 75px;
  }
}

@media screen and (min-width: 768px) {
  .registration-type-tile {
    height: 100%;
  }
}
</style>

<template>
  <div>
    <slot name="instruction"> </slot>
    <div class="d-flex justify-content-center">
      <b-button variant="secondary" class="mr-2" @click="sendOtp">
        Invia codice di verifica
      </b-button>
      <b-button
        variant="secondary text-white"
        class="ml-2"
        @click="showManualVerificationModal"
        v-if="company && company.level === 5 && forceable"
      >
        Verifica numero di telefono fuori piattaforma
      </b-button>
    </div>
    <b-modal
      id="modal-otp"
      size="lg"
      title="Verifica OTP"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      hide-footer
    >
      <b-overlay
        :show="verifying || otp === null"
        class="text-center px-2 py-1"
        v-if="!showBlocK && otp"
      >
        <div class="text-center" v-if="otp.status === 1">
          <h6>Codice verificato correttamente!</h6>
          <p>
            Grazie per aver completato la procedura. Clicca su "Chiudi" per
            proseguire.
          </p>
          <b-button variant="popup-button" @click="closeModal">Chiudi</b-button>
        </div>
        <template v-else>
          <h5>
            Abbiamo inviato via SMS il codice di verifica al numero
            {{ telephoneNumber }}
          </h5>
          <h6 class="pt-4">Inserisci il codice numerico ricevuto via SMS:</h6>
          <b-form id="otp-code" @submit.prevent="verifyOtp">
            <b-form-group class="pb-4">
              <template v-for="(npt, ind) in Object.keys(form)">
                <input
                  type="text"
                  class="digits text-center"
                  :id="npt"
                  v-model="form[npt]"
                  maxlength="1"
                  required
                  @input="focusNext(ind + 2)"
                  @keydown="clearPrev(npt)"
                  :class="
                    form[npt] !== null && isNaN(parseInt(form[npt]))
                      ? 'border-danger'
                      : ''
                  "
                />
              </template>
            </b-form-group>
            <h5
              class="text-danger"
              v-if="(sendTry > 0 && otp.status < 0) || otp.status > 1"
            >
              {{ statusMap[otp.status] }}
            </h5>
            <p v-if="timer > 0">
              Il codice inviato scadrà tra
              <span class="text-secondary"> {{ timer }}</span> secondi
            </p>
            <p v-else class="text-danger">Codice scaduto</p>
            <!-- <div class="text-center" v-if="timer <= 260"> -->
            <div class="text-center" v-if="timer <= 299">
              <p>
                Non hai ricevuto il codice?
                <span
                  class="underlined fw-bolder text-secondary point"
                  @click="sendOtp"
                  >Invia nuovo codice</span
                >
              </p>
              <p v-if="!sendData.secondary && sendData.cause !== 1">
                In alternativa,
                <span
                  class="underlined fw-bolder text-secondary point"
                  @click="sendOtpToSecondary"
                  >invia OTP al numero di riserva
                </span>
                (se disponibile).
              </p>
            </div>
            <b-button variant="popup-button mx-2" @click="closeModal"
              >Chiudi</b-button
            >
            <b-button type="submit" variant="popup-button">Verifica</b-button>
          </b-form>
        </template>
      </b-overlay>
      <div v-else>
        <h5>Non è stato possibile completare la verifica.</h5>
        <p class="text-justify">
          Controlla che il numero di contatto memorizzato in anagrafica sia
          corretto. In caso di ulteriori difficoltà contattare l'assistenza.
        </p>
      </div>
    </b-modal>
    <b-modal
      id="modal-manual-verification"
      size="lg"
      title="Verifica Manuale"
      centered
      hide-footer
    >
      <h5 class="mb-5 text-center">
        Per favore, chiama il numero dell'utente per verificare il contatto.
        <b-button
          size="sm"
          class="ml-2"
          variant="popup-button"
          @click="callPatient"
          >Chiama <b-icon icon="telephone-fill" flip-h rotate="25"></b-icon>
        </b-button>
      </h5>

      <div class="text-center">
        <b-button
          variant="secondary"
          class="mr-2"
          @click="closeManualVerificationModal"
        >
          Annulla
        </b-button>
        <b-button
          variant="secondary"
          class="ml-2"
          @click="markNumberAsVerified"
        >
          Contrassegna numero come verificato
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { utilityService } from "@/_services";
import { mapState, mapActions } from "vuex";
export default {
  name: "OtpValidation",
  props: [
    "user",
    "cause",
    "validatable",
    "second",
    "phoneNumber",
    "hashId",
    "forceable",
  ],
  data() {
    return {
      form: {
        digit1: null,
        digit2: null,
        digit3: null,
        digit4: null,
        digit5: null,
        digit6: null,
      },
      otp: null,
      statusMap: {
        0: "codice di verifica inviato",
        "-1": "errore di sistema",
        1: "codice verificato correttamente",
        2: "codice errato",
        3: "codice scaduto",
        4: "codice già verificato",
        5: "codice cancellato",
      },
      timer: 0,
      interval: null,
      sendTry: 0,
      showBlocK: false,
      verifying: false,
      sendData: {
        cause: 0,
        validatable_id: this.user.hashid,
        secondary: false,
      },
    };
  },
  watch: {
    second: function (val) {
      this.sendData.secondary = val;
    },
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("company", ["company"]),
    telephoneNumber() {
      var text = " al numero ";
      if (this.otp.number) {
        const tel = this.otp.number;
        text += tel[0] + tel.slice(-2).padStart(tel.length - 1, "*");
      }
      return text;
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    verifyOtp() {
      const code = Object.keys(this.form)
        .map((el, ind) => this.form[el])
        .join("");
      if (!isNaN(code)) {
        this.verifying = true;
        // console.log("code", code);
        const self = this;
        utilityService
          .verifyOtp(this.otp.hashid, { code: code })
          .then((res) => {
            // console.log(res);
            self.otp = res.data.data;
            self.verifying = false;
            if (res.data.data.status === 1) {
              self.stopTimeout();
              self.successAlert("OTP Verificato!");
            }
          })
          .catch((error) => {
            console.log(error);
            self.verifying = false;
            self.$emit("otp-validated", false);
            self.errorAlert("Non è stato possibile effettuare la procedura.");
          });
      }
    },
    sendOtp() {
      if (this.sendTry === 3) {
        this.showBlock = true;
        return;
      }
      const self = this;
      utilityService
        .sendOtp(this.user.hashid, this.sendData)
        .then((res) => {
          self.startTimeout();
          self.sendTry++;
          self.otp = res.data.data;
          self.$bvModal.show("modal-otp");
        })
        .catch((error) => {
          console.log(error);
          var mesg = "Non è stato possibile effettuare la procedura.";
          if (error.status == 400) {
            mesg = error.data.message;
          }
          self.errorAlert(mesg);
        });
    },
    startTimeout() {
      this.timer = 299;
      const self = this;
      this.interval = window.setInterval(function () {
        if (self.timer === 0) {
          return self.stopTimeout();
        }
        self.timer--;
      }, 1000);
    },
    stopTimeout() {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.interval = null;
      this.timer = 0;
    },
    focusNext(n) {
      if (n <= 6) {
        document.getElementById("digit" + n).focus();
      }
    },
    clearPrev(npt) {
      if (this.form[npt] !== null) {
        this.form[npt] = null;
      }
    },
    closeModal() {
      this.$bvModal.hide("modal-otp");
      this.$emit("otp-validated", this.otp.status === 1);
      this.stopTimeout();
      this.form = {
        digit1: null,
        digit2: null,
        digit3: null,
        digit4: null,
        digit5: null,
        digit6: null,
      };
    },
    sendOtpToSecondary() {
      this.sendData.secondary = true;
      const self = this;
      this.$nextTick(self.sendOtp);
    },
    showManualVerificationModal() {
      this.$bvModal.show("modal-manual-verification");
    },
    closeManualVerificationModal() {
      this.$bvModal.hide("modal-manual-verification");
    },
    markNumberAsVerified() {
      utilityService
        .numberVerification(this.hashId)
        .then((response) => {
          this.closeManualVerificationModal();
          this.successAlert("Numero contrassegnato come verificato.");
          this.$emit("number-verified");
        })
        .catch((error) => {
          console.error("Errore durante la verifica del numero:", error);
          this.errorAlert(
            "Si è verificato un errore durante la verifica del numero."
          );
        });
    },
    callPatient() {
      window.location.href = `tel:${this.phoneNumber}`;
    },
  },
  created() {
    if (this.cause) {
      this.sendData.cause = this.cause;
    }
    if (this.validatable) {
      this.sendData.validatable_id = this.validatable;
    }
    if (this.second) {
      this.sendData.secondary = this.second;
    }
  },
};
</script>
<style lang="scss">
.digits {
  height: 4rem;
  width: 4rem;
  border: 2px solid $secondary;
  display: inline-block;
  border-radius: $border-radius;
  margin: 5px;
  padding: 15px;
}

.digits:focus,
.digits:focus-visible,
.digits:active {
  border-color: $secondary;
  box-shadow: none !important;
  outline: none !important;
}
</style>

import { userService } from "@/_services";
import router from "@/_router/index";

const user = JSON.parse(localStorage.getItem("user"));

const state = user
  ? {
      status: { loggedIn: true, loggingIn: false, registering: null },
      user: user,
    }
  : {
      status: { loggedIn: null, loggingIn: false, registering: null },
      user: null,
    };

const actions = {
  login({ dispatch, commit }, form) {
    commit("loginRequest");
    userService
      .login(form)
      .then((response) => {
        // console.log("user success-store",response)
        commit("loginSuccess", response.data.data);
        dispatch("settings/setActiveServices", "", { root: true });
        if (form.redirect) {
          console.log("redirect to ", form.redirect);
          router.push({ path: form.redirect });
        } else if (
          response.data.data.email &&
          response.data.data.email_verified_at === null
        ) {
          console.log("email not validated");
          router.push({
            name: "EmailVerification",
            params: { status: "invalid" },
          });
        } else if (response.data.data.number_verified_at === null) {
          console.log("number not validated");
          router.push({
            name: "NumberVerification",
          });
        } else {
          // console.log("going home");
          router.push({ name: "Home" });
        }
        // router.push({ name: "Home" });
        return Promise.resolve(response);
      })
      .catch((error) => {
        if (error.status == 401) {
          router.push({ name: "Disabled" });
        } else if (error.status == 429) {
          var mess =
            "Troppe richieste errate. Richiedi il recupero password oppure contatta l'assistenza.";
          dispatch("alert/error", mess, { root: true });
        }
        // console.log("login failed-store",error.status)
        commit("loginFailure", error);
        return Promise.reject(error);
      });
  },
  logout({ dispatch, commit }) {
    userService
      .logout()
      .then((res) => {
        // console.log(res)
        router.push("/login");
        commit("logout");
        dispatch("company/unsetCompany", null, { root: true });
      })
      .catch((error) => {
        dispatch("alert/error", error.data.message, { root: true });
      });
  },
  register({ dispatch, commit }, user) {
    commit("registerRequest");
    userService.register(user).then(
      (user) => {
        commit("registerSuccess", user.data.data);
        router.push({
          name: "NumberVerification",
        });
        //  router.push({
        //     name: "EmailVerification",
        //     params: { status: "invalid" },
        //   });
        setTimeout(() => {
          // display success message after route change completes
          dispatch(
            "alert/success",
            "Registrazione Completata! Controlla la tua casella di posta elettronica",
            { root: true }
          );
        });
      },
      (error) => {
        // console.log("error",error)
        var msg = "Non è stato possibile completare la registrazione.";
        // if(error.data.errors){
        //     msg="";
        //     Object.keys(error.data.errors).map(function(el,key){
        //         msg += error.data.errors[el][0] + ". "
        //     })
        // }
        if (error.status == 422) {
          msg = "";
          Object.keys(error.data.errors).map(function (el) {
            msg += error.data.errors[el] + ". ";
          });
        }
        commit("registerFailure", error);
        dispatch("alert/error", msg, { root: true });
      }
    );
  },
  resend({ dispatch, commit }) {
    userService
      .resend()
      .then((response) => {
        dispatch(
          "alert/success",
          "Mail inviata! Controlla la tua posta elettronica.",
          { root: true }
        );
      })
      .catch((error) => {
        var msg = "email non valida";
        if (error.status == 400) {
          msg = "Esegui l'accesso per reinviare la mail di conferma";
        }
        if (error.status == 404) {
          msg = "Email già verificata";
          router.push({
            name: "EmailVerification",
            params: { status: "already" },
          });
        }
        dispatch("alert/error", msg, { root: true });
      });
  },
  recoverPassword({ dispatch, commit }, form) {
    userService
      .recoverPassword(form)
      .then((res) => {
        // console.log(res)
        commit("recoverAction", true);
        dispatch(
          "alert/success",
          "Mail inviata! Controlla la tua posta elettronica.",
          { root: true }
        );
      })
      .catch((error) => {
        // console.log(error)
        dispatch("alert/error", "email non valida", { root: true });
      });
  },
  recoverPasswordViaCf({ dispatch, commit }, form) {
    userService
      .recoverPasswordViaCf(form)
      .then((res) => {
        // //console.log(res)
        commit("recoverAction", true);
        dispatch("alert/success", "Messaggio inviato!", { root: true });
      })
      .catch((error) => {
        // console.log(error)
        dispatch("alert/error", "codice fiscale non valido", { root: true });
      });
  },
  resetPassword({ dispatch, commit }, form) {
    userService
      .resetPassword(form)
      .then((res) => {
        // console.log(res)
        commit("loginSuccess", res.data.data);
        router.push({ name: "Home" });
        dispatch("alert/success", "Password reimpostata!", { root: true });
      })
      .catch((error) => {
        // console.log(error)
        commit("recoverAction", false);
        dispatch("alert/error", "Link scaduto. Ripetere operazione", {
          root: true,
        });
      });
  },
  setAnagrafica({ dispatch, commit }, { form, isPost }) {
    if (isPost) {
      userService
        .saveAnagrafica(form)
        .then((res) => {
          // console.log(res)
          commit("editAnagrafica", res.data.data);
          // router.push({name:"Home"});
          dispatch("alert/success", "Anagrafica aggiornata con successo!", {
            root: true,
          });
        })
        .catch((error) => {
          var msg = "Si è verificato un errore";
          if (error.status == 422) {
            msg = "";
            Object.keys(error.data.errors).map(function (el) {
              msg += error.data.errors[el] + ". ";
            });
          } else if (error.status == 400) {
            msg = error.message;
          }
          // console.log(error)
          // commit('recoverAction',false);
          // dispatch('alert/error', "Link scaduto. Ripetere operazione", { root: true });
        });
    } else {
      userService
        .updateAnagrafica(form)
        .then((res) => {
          // console.log(res)
          commit("editAnagrafica", res.data.data);
          // router.push({name:"Home"});
          dispatch("alert/success", "Anagrafica aggiornata con successo!", {
            root: true,
          });
          dispatch("me");
        })
        .catch((error) => {
          var msg = "Si è verificato un errore";
          if (error.status == 422) {
            msg = "";
            Object.keys(error.data.errors).map(function (el) {
              msg += error.data.errors[el] + ". ";
            });
          } else if (error.status == 400) {
            msg = error.data.message;
          }
          // console.log(error)
          // commit('recoverAction',false);
          // dispatch('alert/error', "Link scaduto. Ripetere operazione", { root: true });
        });
    }
  },
  me({ dispatch, commit }, token = null) {
    userService
      .me(token)
      .then((res) => {
        commit("loginSuccess", res.data.data);
        commit("company/setCompany", res.data.data.my_company, { root: true });
        dispatch("settings/setActiveServices", "", { root: true });
        if (token) {
          if (data.redirect) {
            router.push({ path: data.redirect });
          } else {
            router.push({ name: "Home" });
          }
        }
      })
      .catch((error) => {
        console.log("error", error.data.message);
        if (error.status == 401) {
          if (error.data.message === "Email non verificata") {
            router.push({
              name: "EmailVerification",
              params: { status: "invalid" },
            });
          } else if (error.data.message === "Numero telefono non verificato") {
            router.push({
              name: "NumberVerification",
            });
          } else {
            commit("logout");
            router.push({ name: "Disabled" });
          }
        } else if (error.status == 401) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          localStorage.removeItem("company");
          router.push("/login");
        } else {
          commit("logout");
          router.push("/login");
        }
        // dispatch('alert/error', "Link scaduto. Ripetere operazione", { root: true });
      });
  },

  validateEmail({ dispatch, commit }, data = null) {
    let token = data ? data.token : null;
    userService
      .validateEmail(token)
      .then((res) => {
        // //console.log(res)
        commit("loginSuccess", res.data.data);
        commit("company/setCompany", res.data.data.my_company, { root: true });
        if (
          res.data.data &&
          res.data.data.email &&
          res.data.data.email_verified_at
        ) {
          commit("validEmail", res.data.data).email_verified_at;
          if (data.redirect) {
            router.push({ path: data.redirect });
          } else {
            router.push({ name: "Home" });
          }
        }
        if (
          res.data.data &&
          !res.data.data.email &&
          res.data.data.number_verified_at
        ) {
          commit("validEmail", res.data.data).email_verified_at;
          if (data.redirect) {
            router.push({ path: data.redirect });
          } else {
            router.push({ name: "Home" });
          }
        }
      })
      .catch((error) => {
        if (error.status == 401) {
          commit("logout");
          router.push({ name: "Disabled" });
        }
        // commit("logout");
        // router.push("/login");
        // dispatch('alert/error', "Link scaduto. Ripetere operazione", { root: true });
      });
  },
  unMe({ dispatch, commit }) {
    commit("logout");
    router.push("/login");
  },
  changePassword({ dispatch, commit }, form) {
    userService
      .changePsw(form)
      .then((res) => {
        // console.log(res)
        dispatch("alert/success", "Password reimpostata!", { root: true });
      })
      .catch((error) => {
        dispatch(
          "alert/error",
          "Password attuale errata. Se non ricordi la password, esegui la procedura di recupero.",
          { root: true }
        );
      });
  },
  setTemporaryPasswordFalse({ commit }) {
    commit("untemporaryPassword");
  },
  setExpiredPasswordFalse({ commit }) {
    commit("unexpirePassword");
  },
  resetRecoverAction({ commit }) {
    commit("recoverAction", false);
  },
  deleteProfile({ dispatch, commit }) {
    userService
      .deleteProfile()
      .then((response) => {
        dispatch("alert/success", "Il tuo profilo è stato eliminato", {
          root: true,
        });
        setTimeout(function () {
          // commit("logout");
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          localStorage.removeItem("company");
          router.push("/login");
        }, 3000);
        return Promise.resolve(response);
      })
      .catch((error) => {
        dispatch("alert/error", "Non è stato possibile eliminare il profilo.", {
          root: true,
        });
        return Promise.reject(error);
      });
  },
};

const mutations = {
  loginRequest(state) {
    state.status.loggingIn = true;
    state.user = user;
  },
  loginSuccess(state, user) {
    localStorage.removeItem("sessionExpired");
    state.status.loggedIn = true;
    state.status.loggingIn = false;
    state.user = user;
  },
  loginFailure(state) {
    state.status.loggedIn = false;
    state.status.loggingIn = false;
    state.user = null;
  },
  logout(state) {
    localStorage.removeItem("sessionExpired");
    state.status.loggedIn = null;
    state.status.loggingIn = false;
    state.user = null;
  },
  registerRequest(state) {
    state.status.registering = true;
  },
  registerSuccess(state, user) {
    // state.status = {};
    state.status.loggedIn = true;
    state.status.loggingIn = false;
    state.status.registering = null;
    state.user = user;
  },
  registerFailure(state, error) {
    state.status.loggedIn = false;
    state.status.loggingIn = false;
    state.status.registering = false;
    state.user = null;
  },
  recoverAction(state, stat) {
    state.status = { loggedIn: null, loggingIn: false, recoverSent: stat };
  },
  editAnagrafica(state, data) {
    state.user.anagrafica = data;
  },
  untemporaryPassword(state) {
    state.user.temporary_password = false;
  },
  unexpirePassword(state) {
    state.user.expired_password = false;
  },
};

export const utente = {
  namespaced: true,
  state,
  actions,
  mutations,
};

<template>
  <div id="resizable-draggable">
    <b-iconstack
      id="close-handle"
      @click="$emit('close-banca-dati')"
      font-scale="1.8"
    >
      <b-icon stacked icon="x" variant="danger" scale="1"></b-icon>
      <b-icon stacked icon="circle" variant="extra-light"></b-icon>
    </b-iconstack>
    <div id="content" class="d-flex flex-column">
      <b-container class="p-2 flex-grow-1" fluid>
        <iframe
          :src="openBancaDati.url"
          width="100%"
          height="100%"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </b-container>
    </div>
    <div
      id="resize-handle"
      class="d-flex align-items-center justify-content-center"
    >
      <b-iconstack font-scale="1.8">
        <b-icon
          stacked
          icon="arrows-angle-expand"
          rotate="90"
          variant="secondary"
          scale="0.5"
        ></b-icon>
        <b-icon stacked icon="circle" variant="extra-light"></b-icon>
      </b-iconstack>
    </div>
    <b-iconstack
      id="new-tab-handle"
      @click="openInNewTab"
      font-scale="1.8"
      style="position: absolute; bottom: 0; left: 0; cursor: pointer"
      title="Apri in una nuova scheda"
    >
      <b-icon
        stacked
        icon="arrow-up-right"
        variant="secondary"
        scale="0.6"
      ></b-icon>
      <b-icon stacked icon="circle" variant="extra-light"></b-icon>
    </b-iconstack>
  </div>
</template>

<script>
export default {
  name: "FloatingIframe",
  props: {
    openBancaDati: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isResizing: false,
    };
  },
  mounted() {
    this.initDraggable();
    this.initResizable();
  },
  beforeDestroy() {
    document.removeEventListener("mousemove", this.onDrag);
    document.removeEventListener("mouseup", this.stopDrag);
    document.documentElement.removeEventListener("mousemove", this.onResize);
    document.documentElement.removeEventListener("mouseup", this.stopResize);
  },
  methods: {
    initDraggable() {
      const element = document.getElementById("resizable-draggable");

      const startDrag = (e) => {
        // Calcola l'offset iniziale dall'angolo superiore sinistro dell'elemento
        const offsetX = e.clientX - element.getBoundingClientRect().left;
        const offsetY = e.clientY - element.getBoundingClientRect().top;

        // Quando si muove il mouse, aggiorna la posizione dell'elemento
        const onDrag = (e) => {
          element.style.left = e.clientX - offsetX + "px";
          element.style.top = e.clientY - offsetY + "px";
        };

        const stopDrag = () => {
          document.removeEventListener("mousemove", onDrag);
          document.removeEventListener("mouseup", stopDrag);
        };

        document.addEventListener("mousemove", onDrag);
        document.addEventListener("mouseup", stopDrag);
      };

      element.addEventListener("mousedown", startDrag);
    },
    initResizable() {
      const element = document.getElementById("resizable-draggable");
      const resizeHandle = document.getElementById("resize-handle");

      const startResize = (e) => {
        this.isResizing = true;
        const startX = e.clientX;
        const startY = e.clientY;
        const startWidth = parseInt(window.getComputedStyle(element).width, 10);
        const startHeight = parseInt(
          window.getComputedStyle(element).height,
          10
        );

        const onResize = (e) => {
          if (this.isResizing) {
            element.style.width = startWidth - e.clientX + startX + "px";
            element.style.height = startHeight - e.clientY + startY + "px";
          }
        };

        const stopResize = () => {
          this.isResizing = false;
          document.removeEventListener("mousemove", onResize);
          document.removeEventListener("mouseup", stopResize);
        };

        document.addEventListener("mousemove", onResize);
        document.addEventListener("mouseup", stopResize);
        e.preventDefault();
      };

      resizeHandle.addEventListener("mousedown", startResize);
    },
    openInNewTab() {
      const url = this.openBancaDati.url;
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
#resizable-draggable {
  min-width: 200px;
  min-height: 200px;
  width: 40vw;
  height: 40vh;
  position: fixed;
  cursor: move;
  background-color: $font-light-grey;
  border: 1px solid $font-light-grey;
  box-shadow: 1px 4.5px 5px -3px rgba(0, 0, 0, 0.25) !important;
  z-index: 99999;
  bottom: 10px;
  right: 10px;
  border-radius: 0.85rem;
  display: flex;
  flex-direction: column;
}

#resize-handle {
  width: 25px;
  height: 25px;
  background-color: white;
  // border: 2px solid $primary;
  border-radius: 50%;
  position: absolute;
  cursor: nwse-resize;
}

#content {
  flex: 1;
}

#close-handle {
  // width: 25px;
  // height: 25px;
  background-color: white;
  // border: 2px solid $danger;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

#new-tab-handle {
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 50%;
}
</style>

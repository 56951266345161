<template>
  <b-spinner v-if="loading"></b-spinner>
  <div v-else class="pb-4 mb-4 ">
    <div v-if="scaricabili.manuali">
      <h4 class="pb-1 mt-1 mb-4">Manuali d'uso</h4>
      <b-row v-for="manuale in scaricabili.manuali" :key="manuale.title">
        <b-col>
          <h5>
            <a :href="manuale.path" target="_blank" class="text-secondary point">
              <u>{{ manuale.title }}</u></a
            >
          </h5>
          <p>{{ manuale.description }}</p>
        </b-col>
      </b-row>
    </div>
    <div v-if="scaricabili.video">
      <h4 class="pb-1 mt-1 mb-4">Video Tutorial</h4>
      <b-row v-for="video in scaricabili.video" :key="video.title">
        <b-col>
          <h5 class="text-secondary">
            {{ video.title }}
          </h5>
          <p>{{ video.description }}</p>
          <b-col cols="12" md="10" lg="8" xl="7">
            <b-embed type="video" controls poster="/assets/"+$root.scope+"/logo.svg">
              <source :src="video.path" type="video/mp4" />
            </b-embed>
          </b-col>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { utilityService } from "@/_services";
export default {
  name: "GuidaOperatore",
  methods: {
    fetchData() {
      const self = this;
      utilityService
        .getScaricabili()
        .then(function (res) {
          self.scaricabili = res.data.data;
          self.loading = false;
        })
        .catch(function (err) {
          console.log(err);
          self.loading = false;
        });
    },
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      scaricabili: {
        manuali: null,
        video: null,
      },
      loading: true,
    };
  },
};
</script>
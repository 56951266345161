export default {
  inserted(el) {
    const capitalizeOptions = () => {
      Array.from(el.options).forEach(option => {
        const firstChar = option.text.charAt(0);
        const capitalizedChar = firstChar.toUpperCase();
        if (firstChar !== capitalizedChar) {
          option.text = capitalizedChar + option.text.slice(1);
        }
      });
    };

    capitalizeOptions();

    var observer = new MutationObserver(capitalizeOptions);
    // console.log("removedobserver txt", observer);
    if (!observer) {
      try {
        observer = new WebKitMutationObserver(capitalizeOptions);
      } catch (exc) {
        // console.log(exc);
      }
    }
    if (observer) {
      observer.observe(el, { childList: true, subtree: true });
      el._observer = observer;
    }
    // const observer = new MutationObserver(capitalizeOptions)
    // observer.observe(el, { childList: true, subtree: true })
    // el._observer = observer
  },
  unbind: function (el) {
    if (el._observer) {
      el._observer.disconnect();
      delete el._observer;
    }
  }
};

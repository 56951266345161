<template>
  <div>
    <b-iconstack
      v-if="onlysupport"
      font-scale="4.1"
      class="point"
      @click="$bvModal.show(modalName)"
    >
      <b-icon stacked icon="circle-fill" variant="primary"></b-icon>
      <b-icon
        stacked
        icon="telephone-fill"
        scale="0.6"
        variant="white"
      ></b-icon>
    </b-iconstack>
    <b-modal :id="modalName" centered size="xl" hide-footer>
      <div class="py-4 px-3 text-center">
        <br />
        <br />
        <h1 class="huge__font text-secondary">
          <span v-if="onlysupport">Chiama il numero verde</span>
          <span v-else> Per prenotare chiama il numero verde </span>
        </h1>
        <h1 class="huge__font text-secondary py-4">
          <span>
            <b-iconstack font-scale="2.1">
              <b-icon stacked icon="circle-fill" variant="primary"></b-icon>
              <b-icon
                stacked
                icon="telephone-fill"
                scale="0.6"
                variant="white"
              ></b-icon>
            </b-iconstack>
          </span>
          <span style="vertical-align: super"> 800.770.272</span>
        </h1>
        <b-row>
          <b-col class="col-11 col-md-10 col-lg-8 mx-auto">
            <h2 class="p-4">
              I nostri operatori sono disponibili dal lunedì al venerdì dalle
              ore 9:00 alle ore 18:00
            </h2>
          </b-col>
        </b-row>
        <br />
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "CallSupportModal",
  props: ["onlysupport"],
  computed: {
    modalName: function () {
      if (!this.onlysupport) {
        return "prenotazionesupport";
      }
      return "callsupport";
    },
  },
};
</script>
<style>
.huge__font {
  font-size: 3rem !important;
  font-weight: 700;
}
</style>

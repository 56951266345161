import config from "../_config/config";
import { authHeader, postHeader } from "../_helpers";
import axios from "axios";

axios.defaults.baseURL = config.apiUrl;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.put["X-Requested-With"] = "XMLHttpRequest";

export const utilityService = {
  regioni,
  province,
  comuni,
  products,
  searchUser,
  getAvailableExams,
  searchPatologiaCronica,
  searchPatologiaRara,
  getAnnotations,
  searchPatologiaIcd,
  getIrstKeys,
  getSubtypes,
  getScaricabili,
  verifyOtp,
  sendOtp,
  setAssistito,
  searchComuneViaCodiceCatastale,
  listaCodiciEsenzione,
  downloadResource,
  getAslInfo,
  getCompanyOperators,
  nazioni,
  supportRequest,
  getPaymentSettings,
  getProjects,
  getHealthServicesSuggestions,
  getCRCompany,
  getFonti,
  numberVerification
};

function regioni() {
  return axios
    .get("utilities/regioni")
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function province(form = null) {
  var endpoint = "utilities/province";
  if (form.regione) {
    endpoint = "utilities/province/by/" + form.regione;
  }
  return axios
    .get(endpoint)
    .then((response) => {
      if (response.data.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function comuni(form = null) {
  var endpoint = "utilities/comuni";
  if (form.regione) {
    endpoint = "utilities/comuni/by/regione/" + form.regione;
  }
  if (form.provincia) {
    endpoint = "utilities/comuni/by/provincia/" + form.provincia;
  }
  return axios
    .get(endpoint)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function products(filters) {
  const sett = { params: filters };
  return axios
    .get("utilities/products/concura/search", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function searchUser(filters) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("utilities/user/search", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getAvailableExams(filters) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("exams", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function searchPatologiaCronica(filters) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("utilities/patologia/cronica/search", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function searchPatologiaRara(filters) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("utilities/patologia/rara/search", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getAnnotations(hashIdPatient, pickedYear) {
  const sett = {
    headers: authHeader(),
    params: { user: hashIdPatient, year: pickedYear },
  };
  return axios
    .get("annotations", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function searchPatologiaIcd(filters) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("utilities/patologia/icd/search", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getIrstKeys(filters) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("utilities/irst/keys", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getSubtypes() {
  const sett = { headers: authHeader() };
  return axios
    .get("utilities/subtypes", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getScaricabili() {
  const sett = { headers: authHeader() };
  return axios
    .get("resources/concura/public/scaricabili", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function sendOtp(hashid, data) {
  const sett = { headers: authHeader() };
  const endpoint = "otp/send/" + hashid;
  return axios
    .post(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function verifyOtp(hashid, data) {
  const sett = { headers: authHeader() };
  const endpoint = "otp/verify/" + hashid;
  return axios
    .post(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function setAssistito(hashid) {
  const sett = { headers: authHeader() };
  const endpoint = "companies/own/assistito/" + hashid;
  return axios
    .patch(endpoint, {}, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function searchComuneViaCodiceCatastale(codice) {
  const sett = { headers: authHeader() };
  const endpoint = "utilities/comuni/by/catastale/" + codice;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function listaCodiciEsenzione(filters) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("pathologies/exemptioncodes/list", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function downloadResource(hash) {
  // const sett = { headers:authHeader(), responseType:'blob', Accept:"application/pdf" };
  const sett = { headers: authHeader(), responseType: "blob" };
  var endpoint = "resources/private/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getAslInfo(filters) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("utilities/aslinfo", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getCompanyOperators(hashid) {
  const sett = { headers: authHeader() };
  var endpoint = "companies/" + hashid + "/operators";
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function supportRequest(form) {
  const sett = { headers: { ...authHeader() } };
  return axios
    .post("utilities/ticket", JSON.stringify(form), sett)
    .then((response) => {
      // console.log("response", response.data.data);
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function nazioni() {
  return axios
    .get("utilities/paesi")
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getPaymentSettings(filters = { pagamenti: 1 }) {
  const sett = { headers: { ...authHeader(), filters: filters } };
  return axios
    .get("utilities/settings", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getProjects(filters = null) {
  const sett = { filters: filters };
  return axios
    .get("utilities/projects", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getHealthServicesSuggestions(filters = null) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("healthservices/suggestions/name", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getCRCompany(filters) {
  const sett = { headers: authHeader(), params: filters};
  return axios
    .get("companies/cr/default", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getFonti(ref) {
  const sett = { headers: authHeader(), params:{ref} }
  return axios
  .get('users/popup/answers', sett)
  .then((response) => {
    return Promise.resolve(response);
  })
  .catch((err) => {
    var error = err.response;
    return Promise.reject(error);
  });
}

function numberVerification(id) {
  const sett = { headers: authHeader()};
  var endpoint = "users/" + id +"/number-verification"
  return axios
   .patch(endpoint, {verified:true}, sett)
   .then((response) => {
      return Promise.resolve(response);
    })
   .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

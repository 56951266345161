import config from "../_config/config";
import { authHeader, postHeader } from "../_helpers";
import axios from "axios";

axios.defaults.baseURL = config.apiUrl;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.put["X-Requested-With"] = "XMLHttpRequest";

export const croomService = {
  getOwnPatients,
  getAlertEvents,
  getAlertEventDetails,
  updateAlertEvent,
  getUserCircoloCura,
  storeNewAlertEvent,
  getOperatorReservations,
  getUserSubscriptions,
  activateMonitoring,
  getUserAlertEvents,
  getUserAlertEventProtocols,
  getAlertEventProtocols,
  storeUserProtocols,
  flagPatientAsView,
  flagPatientAsDoneToday,
  storeCustomProtocol,
  requestUserSubscription,
  getUserSubscriptionStatus,
};

function getOwnPatients(filters = null) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("users/own/patients/", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}
function getAlertEvents(filters = null) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("alertevents", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}
function getAlertEventDetails(hash, filters = null) {
  const sett = { headers: authHeader(), params: filters };
  const endpoint = "alertevents/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}
function updateAlertEvent(hash, data) {
  const sett = { headers: authHeader() };
  const endpoint = "alertevents/" + hash;
  return axios
    .put(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getUserCircoloCura(user, filters) {
  const sett = { headers: authHeader(), params: filters };
  const endpoint = "users/patient/circolocura/" + user;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeNewAlertEvent(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("alertevents/", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}
function getOperatorReservations(filters = null) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("companies/operator/reservations", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}
function getUserSubscriptions(hash, filters = null) {
  const sett = { headers: authHeader(), params: filters };
  const endpoint = "users/subscriptions/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}
function activateMonitoring(hash) {
  const sett = { headers: authHeader() };
  const endpoint = "users/subscriptions/activate/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}
function getUserAlertEvents(hash, filters = null) {
  const sett = { headers: authHeader(), params: filters };
  const endpoint = "alertevents/user/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}
function getUserAlertEventProtocols(hash, filters = null) {
  const sett = { headers: authHeader(), params: filters };
  const endpoint = "alertevents/protocols/user/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}
function getAlertEventProtocols(filters = null) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("alertevents/protocols/default", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}
function storeUserProtocols(hash, data) {
  const sett = { headers: authHeader() };
  const endpoint = "alertevents/protocols/user/" + hash;
  return axios
    .post(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}
function flagPatientAsView(hash, data) {
  const sett = { headers: authHeader() };
  const endpoint = "users/patient/seentoday/" + hash;
  return axios
    .patch(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}
function flagPatientAsDoneToday(hash, data) {
  const sett = { headers: authHeader() };
  const endpoint = "users/patient/donetoday/" + hash;
  return axios
    .patch(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}
function storeCustomProtocol(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("alertevents/protocols/custom", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}
function requestUserSubscription(hash, filters) {
  const sett = { headers: authHeader(), params: filters };
  const endpoint = "users/subscriptions/request/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}
function getUserSubscriptionStatus(hash, filters = null) {
  const sett = { headers: authHeader(), params: filters };
  const endpoint = "users/subscriptions/status/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

import config from "../_config/config";
import { authHeader, postHeader } from "../_helpers";
import axios from "axios";

axios.defaults.baseURL = config.apiUrl;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.put["X-Requested-With"] = "XMLHttpRequest";

export const userService = {
  login,
  logout,
  register,
  resend,
  recoverPassword,
  recoverPasswordViaCf,
  resetPassword,
  saveAnagrafica,
  updateAnagrafica,
  getById,
  update,
  me,
  validateEmail,
  changePsw,
  updateUser,
  deleteProfile,
  // delete: _delete
};

function login(form) {
  return axios
    .post("users/login", JSON.stringify(form))
    .then((response) => {
      if (response.data.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
        localStorage.setItem("token", response.data.data.token);
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function logout() {
  const sett = { headers: authHeader(), params: { client: "web" } };
  return axios
    .get("users/logout", sett)
    .then((response) => {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("company");
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function register(form) {
  return axios
    .post("users/register", JSON.stringify(form))
    .then((response) => {
      // console.log("response", response.data.data);
      if (response.data.data.token) {
        // console.log(JSON.stringify(response.data.data));
        localStorage.setItem("user", JSON.stringify(response.data.data));
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function resend() {
  // const sett = { headers: authHeader(), baseURL: config.apiBaseUrl };
  const sett = { baseURL: config.apiBaseUrl, headers: { ...authHeader() } };
  return axios
    .post("email/resend", {}, sett)
    .then((response) => {
      // console.log("response", response.data.data);
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function recoverPassword(form) {
  return axios
    .post("users/password/forget", JSON.stringify(form))
    .then((response) => {
      // console.log("response", response.data.data);
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function recoverPasswordViaCf(form) {
  return axios
    .post("users/password/forget/cf", JSON.stringify(form))
    .then((response) => {
      // //console.log("response", response.data.data);
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function resetPassword(form) {
  return axios
    .post("users/password/reset", JSON.stringify(form))
    .then((response) => {
      // console.log("response", response.data.data);
      if (response.data.data.token) {
        // console.log(JSON.stringify(response.data.data));
        localStorage.setItem("user", JSON.stringify(response.data.data));
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function saveAnagrafica(form) {
  const sett = { headers: { ...authHeader() } };
  return axios
    .post("users/anagraphic/new", JSON.stringify(form), sett)
    .then((response) => {
      // console.log("response", response.data.data);
      let user = JSON.parse(localStorage.getItem("user"));
      user.anagrafica = response.data.data;
      localStorage.setItem("user", JSON.stringify(user));
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateAnagrafica(form) {
  const sett = { headers: { ...authHeader() } };
  return axios
    .put("users/anagraphic/update", JSON.stringify(form), sett)
    .then((response) => {
      // console.log("response", response.data.data);
      let user = JSON.parse(localStorage.getItem("user"));
      // console.log("user from storage", user);
      user.anagrafica = response.data.data;
      // console.log("user from storage after", user);
      localStorage.setItem("user", JSON.stringify(user));
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function me(token = null) {
  const sett = {
    headers: token ? { Authorization: "Bearer " + token } : authHeader(),
  };
  // console.log("SETT",sett)
  return axios
    .get("users/fresh/me", sett)
    .then((response) => {
      // console.log("response", response.data.data);
      localStorage.setItem("user", JSON.stringify(response.data.data));
      return Promise.resolve(response);
    })
    .catch((err) => {
      // localStorage.removeItem("user");
      // localStorage.removeItem("token");
      // localStorage.removeItem("company");
      var error = err.response;
      return Promise.reject(error);
    });
}

function validateEmail(token = null) {
  const sett = {
    headers: token ? { Authorization: "Bearer " + token } : authHeader(),
  };
  // //console.log("SETT",sett)
  return axios
    .get("users/fresh/me", sett)
    .then((response) => {
      // //console.log("response", response.data.data);
      localStorage.setItem("user", JSON.stringify(response.data.data));
      return Promise.resolve(response);
    })
    .catch((err) => {
      console.log(err);
      // localStorage.removeItem("user");
      // localStorage.removeItem("token");
      // localStorage.removeItem("company");
      var error = err.response;
      return Promise.reject(error);
    });
}

function deleteProfile() {
  const sett = { headers: authHeader() };
  // //console.log("SETT",sett)
  return axios
    .get("users/profile/delete", sett)
    .then((response) => {
      // localStorage.removeItem("user");
      // localStorage.removeItem("token");
      // localStorage.removeItem("company");
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function changePsw(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("users/change/password", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getById(id) {
  //   const requestOptions = {
  //     method: "GET",
  //     headers: authHeader(),
  //   };
  //   return fetch(
  //     `${config.apiUrl + config.apiVersion}/users/${id}`,
  //     requestOptions
  //   ).then(handleResponse);
}

function update(user) {
  //   const requestOptions = {
  //     method: "PUT",
  //     headers: { ...authHeader(), "Content-Type": "application/json" },
  //     body: JSON.stringify(user),
  //   };
  //   return fetch(
  //     `${config.apiUrl + config.apiVersion}/users/${user.id}`,
  //     requestOptions
  //   ).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
// function _delete(id) {
//     const requestOptions = {
//         method: 'DELETE',
//         headers: authHeader()
//     };

//     return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
// }

function updateUser(hash, form) {
  const sett = { headers: { ...authHeader() } };
  return axios
    .put("users/" + hash, JSON.stringify(form), sett)
    .then((response) => {
      // console.log("response", response.data.data);
      let user = JSON.parse(localStorage.getItem("user"));
      user.anagrafica = response.data.data;
      localStorage.setItem("user", JSON.stringify(user));
    });
}

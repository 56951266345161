import config from "@/_config/config";
import { authHeader, postHeader } from "@/_helpers";
import axios from "axios";

axios.defaults.baseURL = config.apiUrl;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.put["X-Requested-With"] = "XMLHttpRequest";

export const paymentService = {
  storeUserPurchaseAndGetPaymentConfig,
  getPurchaseInfo,
  storeUserPayment,
};

function storeUserPurchaseAndGetPaymentConfig(data) {
  const sett = { headers: { ...authHeader() } };
  return (
    axios
      // .post("utilities/payment", data, sett)
      .post("purchases", data, sett)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((err) => {
        var error = err.response;
        return Promise.reject(error);
      })
  );
}

function getPurchaseInfo(hashid) {
  const sett = { headers: { ...authHeader() } };
  const endpoint = "purchases/" + hashid;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeUserPayment(data) {
  const sett = { headers: { ...authHeader() } };
  return axios
    .post("purchases/payment/simple", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}


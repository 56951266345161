<template>
  <div v-if="servicesStatus !== null">
    <h6 class="ml-auto mr-lg-3 mr-2 mt-2 mt-lg-3 text-capitalize d-inline">
      <span
        class="font-weight-bolder text-white mr-2"
        style="vertical-align: bottom"
        >Teleconsulto di Emergenza</span
      >
      <b-form-checkbox
        class="d-inline point"
        :id="'switch_' + serviceref"
        switch
        size="lg"
        v-model="servicesStatus"
        @change="updateServiceStatus"
      ></b-form-checkbox>
      <span style="vertical-align: bottom" class="mr-2">{{
        servicesStatus ? "ON" : "OFF"
      }}</span>
    </h6>
  </div>
  <b-spinner v-else></b-spinner>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { companyService } from "@/_services";
export default {
  name: "OperatorServiceStatusSwitch",
  props: ["serviceref"],
  data() {
    return {
      servicesStatus: null,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    getServiceStatus() {
      const self = this;
      companyService
        .getOperatorServicesStatus(this.serviceref)
        .then((response) => {
          self.servicesStatus = response.data.data.active;
        })
        .catch((error) => {
          console.log(error);
          self.servicesStatus = false;
          self.errorAlert(
            "Non è stato possibile verificare stato servizio operatore"
          );
        });
    },
    updateServiceStatus() {
      const self = this;
      const form = { status: this.servicesStatus };
      companyService
        .updateOperatorServiceStatus(form, this.serviceref)
        .then((response) => {
          self.getServiceStatus();
        })
        .catch((error) => {
          console.log(error);
          self.getServiceStatus();
          self.errorAlert(
            "Non è stato possibile verificare stato servizio operatore"
          );
        });
    },
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
  },
  created() {
    this.getServiceStatus();
  },
};
</script>

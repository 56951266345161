<template>
  <b-modal
    id="show-popup-modal"
    :title="currentTitle"
    hide-footer
    size="lg"
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    header-class="p-4"
    body-class="p-4"
    centered
  >
    <p v-if="currentDescription">{{ currentDescription }}</p>
    <b-container class="text-secondary">
      <b-row>
        <b-col
          cols="6"
          cols-md="3"
          v-for="(option, index) in currentOptions"
          :key="index"
          class="py-3"
        >
          <b-form-checkbox v-model="response" :value="option.value">
            {{ option.text }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-container>
    <p class="d-flex justify-content-end mt-5 mb-0">
      <b-button @click="submit" variant="popup-button">Procedi</b-button>
    </p>
  </b-modal>
</template>

<script>
import { cittadinoService } from "@/_services";
import { mapActions } from "vuex";
export default {
  name: "PopupModal",
  data() {
    return {
      questions: [],
      currentQuestionIndex: 0,
      response: [],
    };
  },
  methods: {
    fetchQuestions() {
      const self = this;
      cittadinoService
        .getCittadinoPopupQuestions(this.form)
        .then(function (response) {
          self.questions = response.data;
          if (self.questions.length > 0) {
            self.$bvModal.show("show-popup-modal");
          }
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          if (error && error.status != 401) {
            self.errorAlert(
              "Non è stato possibile caricare le domande del popup"
            );
          }
        });
    },
    submit() {
      var self = this;

      const form = {
        ref: this.questions[this.currentQuestionIndex].ref,
        response: this.response.join(","),
      };

      cittadinoService
        .postCittadinoPopupAnswer(form)
        .then(function (response) {
          self.successAlert("grazie per la tua risposta!");
          if (self.currentQuestionIndex < self.questions.length - 1) {
            self.currentQuestionIndex++;
            self.response = [];
          } else {
            self.$bvModal.hide("show-popup-modal");
          }
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile inviare la risposta");
        });
    },
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
  },
  computed: {
    currentTitle() {
      if (this.questions.length === 0) return "";
      else return this.questions[this.currentQuestionIndex].title;
    },
    currentDescription() {
      if (this.questions.length === 0) return "";
      else return this.questions[this.currentQuestionIndex].description;
    },
    currentOptions() {
      if (this.questions.length === 0) return "";
      else
        return this.questions[this.currentQuestionIndex].options
          .split(",")
          .map((option, index) => {
            return {
              value: option,
              text: option,
            };
          });
    },
  },
  mounted() {
    this.fetchQuestions();
  },
};
</script>

<style scoped>
.b-container {
  padding-top: 15px;
}
</style>

<template>
  <div>
    <FloatingIframe
      v-if="openBancaDati"
      :openBancaDati="openBancaDati"
      @close-banca-dati="openBancaDati = null"
    />
    <!-- <FloatingIframeOld/> -->

    <div class="w-sm-100 d-md-flex">
      <IdleTracker
        v-if="user.session_timeout_minutes && user.session_timeout_minutes > 0"
        :userSessionTimeout="user.session_timeout_minutes"
      />
      <section class="nav-desktop" style="z-index: 5">
        <NavigationOperatoreCRoom
          v-if="company && company.level === 5"
          :wlistener="innerWidth"
        />

        <NavigationOperatore
          v-else
          :wlistener="innerWidth"
          @open-banca-dati="openBancaDati = $event"
        />
      </section>

      <div class="w-100">
        <OperatoreTopHeader />

        <!-- <section
        id="main-operatore"
        class="bg-extra-light pt-4 px-2 px-md-3 px-lg-4"
      > -->
        <section class="pt-4 px-2 px-md-3 px-lg-4 main-operatore">
          <router-view></router-view>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import NavigationOperatore from "@/app/layout/components/NavigationOperatore.vue";
import NavigationOperatoreCRoom from "@/app/layout/components/NavigationOperatoreCRoom.vue";
import IdleTracker from "@/app/common/components/utilities/IdleTracker.vue";
import FloatingIframe from "@/app/modules/statistiche/components/FloatingIframe.vue";
import OperatoreTopHeader from "@/app/layout/components/OperatoreTopHeader.vue";

export default {
  name: "OperatoreMain",
  components: {
    NavigationOperatore,
    NavigationOperatoreCRoom,
    IdleTracker,
    FloatingIframe,
    OperatoreTopHeader,
    // FloatingIframeOld
  },
  data() {
    return {
      innerWidth: window.innerWidth,
      openBancaDati: null,
    };
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    ...mapState("company", ["companyLoggedIn", "company"]),
    welcomeName() {
      if (this.user && this.user.anagrafica) {
        return (
          this.user.anagrafica.name.toLowerCase() +
          " " +
          this.user.anagrafica.surname.toLowerCase()
        );
      }
      return this.user.username;
    },
  },
  methods: {
    ...mapActions("company", ["logUserCompanyIn", "companyLogin"]),
    innerWidthChanged() {
      // console.log("resize handler");
      this.innerWidth = window.innerWidth;
    },
  },
  created() {
    if (this.user.type !== 1) {
      this.$router.push({ name: "Home" });
    }
    window.addEventListener("resize", this.innerWidthChanged);
  },
  mounted() {
    this.logUserCompanyIn();
    if (
      this.user.expired_password == 1 &&
      this.$route.name !== "profilo operatore"
    ) {
      this.$router.push({
        name: "profilo operatore",
        query: { scope: "expired" },
      });
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
};
</script>
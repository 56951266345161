<template>
  <p
    class="pb_15 nav__item bg-white"
    v-if="!requestingInfoForFullMonitorSubscription"
  >
    <a
      class="d-flex align-items-center pl-1 py-1 general_border_3 point"
      @click="$bvModal.show('modal-assistenza')"
    >
      <b-icon font-scale="1.3" icon="question-circle"></b-icon>
      <span class="mb-0 ml-2">Assistenza</span>
    </a>
    <b-modal
      id="modal-assistenza"
      title="Richiesta di Assistenza"
      centered
      hide-footer
      size="lg"
    >
      <p class="px-3 py-2 bg-extra-light general_border text-justify mx-3">
        Seleziona tra le opzioni quella che descrive meglio l'oggetto della tua
        richiesta, e dopo aver compilato il messaggio clicca su "invia
        richiesta": il sistema inoltrerà una email al centro assistenza che
        prenderà in carico la richiesta nel più breve tempo possibile. In base
        alla richiesta potrai ricevere riscontro via email oppure essere
        contattato da un operatore per via telefonica.
      </p>

      <b-form @submit.prevent="submitHelpRequest">
        <b-row>
          <b-col class="col-12">
            <b-form-group label="Oggetto*" label-for="oggetto" class="mb-0">
              <b-form-select
                v-capitalize-first-select
                id="oggetto"
                v-model="form.object"
                :options="objectOptions"
                required
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col class="col-12 mt-3">
            <b-form-group label="Messaggio*" label-for="messaggio" class="mb-0">
              <b-form-textarea
                v-capitalize-first
                id="messaggio"
                v-model="form.content"
                rows="5"
                required
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <p class="text-left mt-2 ml-3">*Campi obbligatori</p>
        <b-row class="mt-3">
          <b-col class="col-12 text-right">
            <b-button type="submit" variant="popup-button"
              >Invia Richiesta</b-button
            >
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </p>
  <b-modal
    v-else
    :id="
      requestingInfoForFullMonitorSubscription
        ? 'modal-assistenza-full-monitor'
        : 'modal-assistenza'
    "
    title="Richiesta di Assistenza"
    centered
    hide-footer
    size="lg"
  >
    <b-form @submit.prevent="submitHelpRequest">
      <b-row>
        <b-col class="col-12">
          <b-form-group label="Oggetto*" label-for="oggetto" class="mb-0">
            <b-form-select
              v-capitalize-first-select
              id="oggetto"
              v-model="form.object"
              :options="objectOptions"
              required
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col class="col-12 mt-3">
          <b-form-group label="Messaggio*" label-for="messaggio" class="mb-0">
            <b-form-textarea
              v-capitalize-first
              id="messaggio"
              v-model="form.content"
              rows="5"
              required
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <p class="text-left mt-2 ml-3">*Campi obbligatori</p>
      <b-row class="mt-3">
        <b-col class="col-12 text-right">
          <b-button type="submit" variant="popup-button"
            >Invia Richiesta</b-button
          >
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import { utilityService } from "@/_services";
import { mapActions, mapState } from "vuex";

export default {
  name: "SendHelpRequest",
  computed: {
    ...mapState("company", ["companyLoggedIn", "company"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  props: {
    requestingInfoForFullMonitorSubscription: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      objectOptions: [
        {
          value: null,
          text: "Seleziona un oggetto",
        },
        {
          value: "richiesta informazioni",
          text: "Richiesta informazioni",
        },
        {
          value: "richiesta implementazione",
          text: "Richiesta implementazione",
        },
        {
          value: "malfunzionamento",
          text: "Malfunzionamento",
        },
        {
          value: "correzione dati errati",
          text: "Correzione dati errati",
        },
        // {
        //   value: "altro",
        //   text: "Altro",
        // },
      ],
      form: {
        object: this.requestingInfoForFullMonitorSubscription
          ? "richiesta informazioni"
          : null,
        content: this.requestingInfoForFullMonitorSubscription
          ? "Vorrei sapere di più sulla sottoscrizione ConCura Full Monitor"
          : null,
      },
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    submitHelpRequest() {
      const self = this;
      utilityService
        .supportRequest(this.form)
        .then((res) => {
          self.successAlert("Richiesta di assistenza inviata con successo");
          self.form = {
            object: null,
            content: null,
          };
        })
        .catch((err) => {
          self.errorAlert("Non è stato possibile inviare la richiesta");
        })
        .finally(() => {
          self.$bvModal.hide(
            self.requestingInfoForFullMonitorSubscription
              ? "modal-assistenza-full-monitor"
              : "modal-assistenza"
          );
        });
    },
  },
};
</script>

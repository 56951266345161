import { utilityService } from "@/_services"
import router from "@/_router/index"

const state = {
  searching: false,
  comuni: null,
  province: null,
  regioni: null,
  asl: null,
  projects:null
}

const actions = {
  getRegioni({ dispatch, commit }) {
    commit("searchingOn")
    utilityService
      .regioni()
      .then((response) => {
        // console.log("regioni success-store",response)
        commit("regioniSuccess", response.data.data)

        return Promise.resolve(response)
      })
      .catch((error) => {
        // console.log("login failed-store",error);
        commit("searchingOff")
        dispatch("alert/error", "Si è verificato un errore", { root: true })
        return Promise.reject(error)
      })
  },
  getProvince({ dispatch, commit }, form) {
    commit("searchingOn")
    utilityService
      .province(form)
      .then((response) => {
        // console.log("regioni success-store",response)
        commit("provinceSuccess", response.data.data)
        return Promise.resolve(response)
      })
      .catch((error) => {
        // console.log("login failed-store",error);
        commit("searchingOff")
        dispatch("alert/error", "Si è verificato un errore", { root: true })
        return Promise.reject(error)
      })
  },
  getComuni({ dispatch, commit }, form) {
    commit("searchingOn")
    utilityService
      .comuni(form)
      .then((response) => {
        // console.log("regioni success-store",response)
        commit("comuniSuccess", response.data.data)
        return Promise.resolve(response)
      })
      .catch((error) => {
        // console.log("login failed-store",error);
        commit("searchingOff")
        dispatch("alert/error", "Si è verificato un errore", { root: true })
        return Promise.reject(error)
      })
  },
  getAsl({ dispatch, commit }, filters = null) {
    if (!filters) {
      filters = { denominazione: true }
    }
    utilityService
      .getAslInfo(filters)
      .then((response) => {
        // //console.log("regioni success-store",response)
        commit("aslSuccess", response.data.data)
        return Promise.resolve(response)
      })
      .catch((error) => {
        dispatch("alert/error", "Si è verificato un errore", { root: true })
        return Promise.reject(error)
      })
  },
  getProjects({ dispatch, commit }, filters = null) {
    utilityService
      .getProjects(filters)
      .then((response) => {
        // //console.log("regioni success-store",response)
        commit("projectsSuccess", response.data.data)
        return Promise.resolve(response)
      })
      .catch((error) => {
        dispatch("alert/error", "Si è verificato un errore", { root: true })
        return Promise.reject(error)
      })
  }
}

const mutations = {
  searchingOn(state) {
    state.searching = true
  },
  searchingOff(state) {
    state.searching = false
  },
  regioniSuccess(state, data) {
    state.searching = false
    state.regioni = data
  },
  provinceSuccess(state, data) {
    state.searching = false
    state.province = data
  },
  comuniSuccess(state, data) {
    state.searching = false
    state.comuni = data
  },
  aslSuccess(state, data) {
    state.searching = false
    state.asl = data
  },
  projectsSuccess(state, data) {
    state.searching = false
    state.projects = data
  }
}

export const utility = {
  namespaced: true,
  state,
  actions,
  mutations
}

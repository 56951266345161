const state = {
  closable: true,
  open: false,
  canNavigate: true,
};

const actions = {
  setOpen({ commit }, status) {
    commit("open", status);
  },
  setClosable({ commit }, status) {
    commit("closable", status);
  },
  setCanNavigate({ commit }, status) {
    commit("canNavigate", status);
  },
  openAndBlock({ commit }, everywhere = false) {
    if (everywhere) {
      commit("openAndBlockEverywhere");
    } else {
      commit("openAndBlock");
    }
  },
  clear({ commit }) {
    commit("clear");
  },
};

const mutations = {
  open(state, status) {
    state.open = status;
  },
  closable(state, status) {
    state.closable = status;
  },
  canNavigate(state, status) {
    state.canNavigate = status;
  },
  openAndBlock(state) {
    state.open = true;
    state.closable = false;
    state.canNavigate = true;
  },
  openAndBlockEverywhere(state) {
    state.open = true;
    state.closable = false;
    state.canNavigate = false;
  },
  clear(state) {
    state.closable = true;
    state.open = false;
    state.canNavigate = true;
  },
};

export const modal = {
  namespaced: true,
  state,
  actions,
  mutations,
};

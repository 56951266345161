import config from "../_config/config";
import { authHeader, postHeader } from "../_helpers";
import axios from "axios";

axios.defaults.baseURL = config.apiUrl;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.put["X-Requested-With"] = "XMLHttpRequest";

export const reservationService = {
  fetchPrenotazioni,
  closeOrder,
  fetchCalendar,
  massiveCancel,
  companyCancelOrReject,
  massiveReport,
  disponibilitaPrestazione,
  disponibilitaServizio,
  storeReservation,
  fetchUserPrenotazioni,
  fetchUserCalendar,
  fetchUserCalendarDay,
  latestReservations,
  userCancelOrReject,
  allUserReservations,
  scaricaPromemoria,
  scaricaCalendar,
  uploadFile,
  getAllegato,
  updateReservation,
  companyConfirmOrder,
  userConfirmOrder,
  storeFilledSurvey,
  connessaSurveys,
  getServiceOpeningForDay,
  getReservationDetails,
  updateTcDuration,
  getUsersReservsInCompany,
  todayReservations,
  updateReservationNotes,
  massiveEvadi,
  massiveEvadiByIds,
  requestReservationDateChange,
  storeReservationMessage,
  getReservationMessage,
  markMessagesAsRead,
  downloadResource,
  storeFilledAslSurvey,
  getStatisticsForSpecificDate,
  markReservationAsHandled,
  getPrenotazioniRicorrentiUser,
  storePrenotazioneRicorrenteUser,
  changeStatusPrenotazioneRicorrenteUser,
  deletePrenotazioneRicorrenteUser,
  updatePrenotazioneRicorrenteUser,
  getUserPaymentInfo,
  getPlatformHealthServices,
  getCompaniesForRequestedHealthService,
  getCompaniesForRequestedService,
  fetchUserCalendarWithEvents,
  CRConfirmOrder,
  CRRejectOrder,
  storeEmergencyReservation,
  getUserPendingEmergencyReservation,
};

function fetchPrenotazioni(filters) {
  var sett = { headers: authHeader() };
  if (filters) {
    sett = { headers: authHeader(), params: filters };
  }
  // var endpoint = "companies/own/reservations";
  return axios
    .get("companies/own/reservations", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function fetchUserPrenotazioni(filters) {
  var sett = { headers: authHeader() };
  if (filters) {
    sett = { headers: authHeader(), params: filters };
  }
  // var endpoint = "companies/own/reservations";
  return axios
    .get("users/own/reservations", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function closeOrder(data) {
  const sett = { headers: authHeader() };
  var endpoint = "reservations/company/close/" + data.hash;
  // var form = data.details !== null ? {message:data.details}:{};
  return axios
    .post(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function fetchCalendar(filters, month = false) {
  const params = { ...filters, month };
  var sett = { headers: authHeader(), params };
  return axios
    .get("companies/own/reservations/calendar", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function fetchUserCalendar(filters) {
  var sett = { headers: authHeader(), params: filters };
  return axios
    .get("users/own/reservations/calendar", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function fetchUserCalendarDay(filters) {
  var sett = { headers: authHeader(), params: filters };
  return axios
    .get("users/own/reservations/onedaycalendar", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function massiveCancel(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("reservations/company/massive/reject", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function companyCancelOrReject(form, action, hash) {
  const sett = { headers: authHeader() };
  var endpoint =
    action == "cancel"
      ? "reservations/own/cancel/"
      : "reservations/company/reject/";
  endpoint += hash;
  return axios
    .post(endpoint, form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function companyConfirmOrder(hash) {
  const sett = { headers: authHeader() };
  var endpoint = "reservations/company/confirm/";
  endpoint += hash;
  console.log("ID passato a companyConfirmOrder:", hash);
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function userConfirmOrder(hash) {
  const sett = { headers: authHeader() };
  var endpoint = "reservations/user/confirm/";
  endpoint += hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function massiveReport(data) {
  const sett = { headers: authHeader(), responseType: "blob" };
  return axios
    .post("reservations/company/massive/report", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function disponibilitaPrestazione(data) {
  const sett = { headers: authHeader(), params: data.params || undefined };
  var endpoint = "healthservices/slots/array/" + data.hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function disponibilitaServizio(data) {
  const sett = {
    headers: authHeader(),
    params: { company: data.company, ...data.params } || undefined,
  };
  var endpoint = "services/slots/array/" + data.hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeReservation(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("reservations", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function latestReservations() {
  const sett = { headers: authHeader() };
  return axios
    .get("users/own/reservations/latest", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function todayReservations() {
  const sett = { headers: authHeader() };
  return axios
    .get("users/own/reservations/today", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function allUserReservations(filters, cancelToken) {
  const sett = {
    headers: authHeader(),
    params: filters,
    cancelToken: cancelToken,
  };
  return axios
    .get("users/own/reservations/all", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        return Promise.reject("canceled");
      }
      return Promise.reject(err.response);
    });
}

function userCancelOrReject(form, action, hash) {
  const sett = { headers: authHeader() };
  var endpoint =
    action == "cancel"
      ? "reservations/own/cancel/"
      : "reservations/user/reject/";
  endpoint += hash;
  return axios
    .post(endpoint, form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}
function scaricaPromemoria(hash) {
  const sett = {
    headers: authHeader(),
    responseType: "blob",
    Accept: "application/pdf",
  };
  var endpoint = "users/own/reservation/download/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function scaricaCalendar(hash) {
  // const sett = { headers:authHeader(), responseType:'blob', Accept:"text/calendar" };
  const sett = { headers: authHeader() };
  var endpoint = "users/own/reservation/calendar/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function uploadFile(data) {
  const sett = { headers: authHeader() };
  var endpoint = "resources/public";
  return axios
    .post(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getAllegato(data) {
  const sett = { headers: authHeader(), responseType: "arraybuffer" };
  var endpoint = "resources/public";
  return axios
    .get(data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateReservation(data) {
  const sett = { headers: authHeader() };
  var endpoint = "reservations/" + data.hashid;
  return axios
    .put(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeFilledSurvey(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("reservations/survey/filled/new", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function connessaSurveys(hash) {
  const sett = { headers: authHeader() };
  var endpoint = "companies/connessa/surveys/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getServiceOpeningForDay(data) {
  const sett = { headers: authHeader(), params: data.filters };
  var endpoint = "services/opening/day/" + data.hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getReservationDetails(hash) {
  const sett = { headers: authHeader() };
  var endpoint = "reservations/details/all/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateTcDuration(data) {
  const sett = { headers: authHeader() };
  var endpoint = "reservations/order/tc/update/" + data.hash;
  return axios
    .put(endpoint, data.form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getUsersReservsInCompany(filters, hash) {
  const sett = { headers: authHeader(), params: filters };
  var endpoint = "reservations/company/users/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateReservationNotes(data, hash) {
  const sett = { headers: authHeader() };
  var endpoint = "reservations/notes/" + hash;
  return axios
    .patch(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function massiveEvadi(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("reservations/company/massive/close", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function massiveEvadiByIds(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("reservations/company/multiple/close", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function requestReservationDateChange(data, hash) {
  const sett = { headers: authHeader() };
  var endpoint = "reservations/date/change/" + hash;
  return axios
    .put(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeReservationMessage(data, hash) {
  const sett = { headers: authHeader() };
  const endpoint = "reservations/message/" + hash;
  return axios
    .post(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getReservationMessage(hash, filters) {
  const sett = { headers: authHeader(), params: filters };
  const endpoint = "reservations/message/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function markMessagesAsRead(data, hash) {
  const sett = { headers: authHeader() };
  const endpoint = "reservations/message/" + hash;
  return axios
    .patch(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function downloadResource(hash) {
  const sett = { headers: authHeader(), responseType: "blob" };
  var endpoint = "resources/private/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeFilledAslSurvey(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("activities/surveys/filled/asl/new", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getStatisticsForSpecificDate($date) {
  const sett = { headers: authHeader(), params: { date: $date } };

  var endpoint = "companies/own/reservations/statisticsbydate";
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function markReservationAsHandled(hashid) {
  const sett = { headers: authHeader() };
  const endpoint = "reservations/handled/" + hashid;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storePrenotazioneRicorrenteUser(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("reservations/frequencies", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getPrenotazioniRicorrentiUser(userHash) {
  const sett = { headers: authHeader() };
  return axios
    .get("reservations/frequencies/users/" + userHash, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;

      return Promise.reject(error);
    });
}

function changeStatusPrenotazioneRicorrenteUser(hash, enabled) {
  const sett = { headers: authHeader() };
  return axios
    .patch("reservations/frequencies/" + hash, { enabled }, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;

      return Promise.reject(error);
    });
}

function deletePrenotazioneRicorrenteUser(hash) {
  const sett = { headers: authHeader() };
  return axios
    .delete("reservations/frequencies/" + hash, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;

      return Promise.reject(error);
    });
}

function updatePrenotazioneRicorrenteUser(data, hash) {
  const sett = { headers: authHeader() };
  return axios
    .put("reservations/frequencies/" + hash, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;

      return Promise.reject(error);
    });
}

function getUserPaymentInfo(hashid) {
  const sett = { headers: { ...authHeader() } };
  const endpoint = "reservations/payment/info/" + hashid;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getPlatformHealthServices(filters) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("healthservices/search/companies", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getCompaniesForRequestedHealthService(
  filters = {},
  cancelToken = null
) {
  const sett = { headers: authHeader(), params: filters, cancelToken };
  return axios
    .get(`healthservices/search/companies`, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        return Promise.reject("canceled");
      }
      var error = err.response;
      return Promise.reject(error);
    });
}

function getCompaniesForRequestedService(ref, filters = {}) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get(`services/${ref}/companies`, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function fetchUserCalendarWithEvents(filters, userHash) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get(`users/${userHash}/agendaevents/calendar/full`, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function CRConfirmOrder(hash) {
  const sett = { headers: authHeader() };
  var endpoint = "reservations/company/confirmCR/";
  endpoint += hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function CRRejectOrder(form, hash) {
  const sett = { headers: authHeader() };
  var endpoint = "reservations/company/rejectCR/";
  endpoint += hash;
  return axios
    .post(endpoint, form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeEmergencyReservation(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("reservations/emergenza", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getUserPendingEmergencyReservation() {
  const sett = { headers: authHeader() };
  return axios
    .get("reservations/emergenza/pending", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

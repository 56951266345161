<template>
  <div class="form pb-2">
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col cols="12" class="mb-2">
          <b-form-group
            label="Tipo di contatto"
            label-for="contacttypes"
            class="text-left"
          >
            <b-form-radio-group
              v-model="form.type"
              name="contacttypes-options"
              class="d-flex my-3"
            >
              <b-form-radio
                v-for="contactType in emergencyContactTypes"
                :value="contactType.value"
                :key="contactType.label"
                >{{ contactType.label }}</b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>
        </b-col>
        <template v-if="form.type === 0">
          <b-col>
            <b-form-group label="Nome*" label-for="name" class="text-left">
              <b-form-input
                class="my-3 text-capitalize"
                name="name"
                type="text"
                placeholder="Nome..."
                v-model="form.name"
                required
                autofocus
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group
              label="Cognome*"
              label-for="surname"
              class="text-left"
            >
              <b-form-input
                class="my-3 text-capitalize"
                name="surname"
                type="text"
                placeholder="Cognome..."
                v-model="form.surname"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </template>
        <b-col v-else cols="12">
          <b-form-group label="Denominazione*" class="text-left">
            <b-form-input
              v-capitalize-first
              class="my-2"
              type="text"
              v-model="form.name"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row cols="1" cols-md="2" cols-xl="3">
        <b-col>
          <b-form-group
            label="Relazione contatto*"
            label-for="relationship"
            class="text-left"
          >
            <b-form-select
              v-capitalize-first-select
              :state="showValidationErrors ? form.relationship !== null : null"
              class="my-3 custom-select"
              :options="emergencyContactRelationshipTypes"
              name="relationship"
              v-model="form.relationship"
            >
              <b-form-select-option :value="null" disabled
                >Seleziona tipo relazione</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Regione*" label-for="regione" class="text-left">
            <b-form-select
              v-capitalize-first-select
              :state="showValidationErrors ? selected.regione !== null : null"
              class="my-3 custom-select text-capitalize"
              :options="regioni"
              name="regione"
              v-model="selected.regione"
              @change="getProvince(selected)"
            >
              <b-form-select-option :value="null" disabled
                >Seleziona Regione</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Provincia*"
            label-for="provincia"
            class="text-left"
          >
            <b-form-select
              v-capitalize-first-select
              :state="showValidationErrors ? selected.provincia !== null : null"
              class="my-3 text-capitalize"
              :options="province"
              name="provincia"
              v-model="selected.provincia"
              @change="getComuni(selected)"
            >
              <b-form-select-option :value="null" disabled
                >Seleziona Provincia</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Comune*" label-for="comune" class="text-left">
            <b-form-select
              v-capitalize-first-select
              :state="showValidationErrors ? selected.comune !== null : null"
              class="my-2 text-capitalize"
              name="comune"
              v-model="selected.comune"
              :key="
                comuni && comuni.length
                  ? comuni[0].comune + comuni.length
                  : null
              "
            >
              <b-form-select-option
                v-for="(com, ind) in comuni"
                :key="ind"
                :value="com"
                @change="setDomicilioData"
                :selected="findComune(com)"
                >{{ com.comune }}</b-form-select-option
              >
              <b-form-select-option :value="null" disabled
                >Seleziona Comune</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Indirizzo e numero civico"
            label-for="address"
            class="text-left"
          >
            <b-form-input
              v-capitalize-first
              class="my-3"
              name="address"
              type="text"
              placeholder="Indirizzo..."
              v-model="form.address"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="CAP" label-for="cap" class="text-left">
            <b-form-input
              class="my-3"
              name="cap"
              type="text"
              placeholder="CAP..."
              v-model="form.cap"
              minlength="5"
              maxlength="5"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Numero di telefono*"
            label-for="tel_number"
            class="text-left"
          >
            <b-form-input
              class="my-3"
              name="tel_number"
              type="text"
              placeholder="Telefono..."
              v-model="form.tel_number"
              minlength="9"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <p class="mb-0 w-100 text-right pt-3">
        <b-button
          type="button"
          variant="popup-button mx-2"
          @click.prevent="handleAnnulla()"
          >Annulla</b-button
        >
        <b-button type="submit" variant="popup-button mx-2"
          >Salva Contatto
        </b-button>
      </p>
    </b-form>
  </div>
</template>

<script>
import Vue from "vue";
import { cittadinoService } from "@/_services";

import * as moment from "moment/moment";
import UploadComponent from "@/app/common/components/utilities/UploadComponent.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "NewEmergencyContact",
  props: ["hashid"],
  computed: {
    ...mapState("utility", ["searching", "comuni", "province", "regioni"]),
  },
  components: {
    UploadComponent,
  },
  data() {
    return {
      selected: {
        regione: null,
        provincia: null,
        comune: null,
      },
      form: {
        user_id: this.hashid,
        type: 0,
        relationship: null,
        regione: null,
        provincia: null,
        comune: null,
        cap: null,
        address: null,
        name: null,
        surname: null,
        tel_number: null,
      },
      showValidationErrors: false,
      emergencyContactRelationshipTypes: [
        "familiare",
        "amico",
        "assistenza",
        "personale medico",
        "personale sanitario",
        "altro",
      ],
      // emergencyContactRelationshipTypes: [
      //   { text: "familiare", value: 0 },
      //   { text: "amico", value: 1 },
      //   { text: "assistenza", value: 2 },
      //   { text: "medico", value: 3 },
      //   { text: "sanitario", value: 4 },
      //   { text: "altro", value: 5 },
      // ],
      emergencyContactTypes: [
        { label: "persona fisica", value: 0 },
        { label: "luogo", value: 1 },
      ],
    };
  },
  methods: {
    ...mapActions("utility", ["getComuni", "getProvince", "getRegioni"]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    setDomicilioData() {
      if (this.selected.comune) {
        this.form.comune = this.selected.comune.comune;
        this.form.provincia = this.selected.comune.provincia;
        this.form.regione = this.selected.comune.regione;
      }
    },
    onSubmit() {
      this.showValidationErrors = true;
      console.log("submit", this.form);
      const self = this;
      cittadinoService
        .storeEmergencyContact(this.form)
        .then(function (res) {
          self.successAlert("Contatto aggiunto correttamente");
          self.$emit("saved-contact");
          self.resetForm();
        })
        .catch(function (err) {
          console.log(err);
          self.errorAlert("Errore nel salvataggio del contatto");
        });
    },
    findComune(comune) {
      if (this.form && this.form.comune) {
        if (comune.comune === this.form.comune) {
          this.selected.comune = comune;
          return true;
        }
      }
      return false;
    },
    handleAnnulla() {
      this.$emit("saved-contact");
      this.resetForm();
    },
    resetForm() {
      this.form = {
        user_id: this.hashid,
        type: 0,
        relationship: 0,
        regione: null,
        provincia: null,
        comune: null,
        cap: null,
        address: null,
        name: null,
        surname: null,
        tel_number: null,
      };
    },
  },
  watch: {
    selected: {
      handler(val) {
        if (val.comune !== null) {
          this.form.comune = this.selected.comune.comune;
          this.form.provincia = this.selected.comune.provincia;
          this.form.regione = this.selected.comune.regione;
        }
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    // this.getRegioni();
  },
};
</script>

import { settingsService } from "@/_services";

const state = {
  actives: null,
  activeRefs: [],
  payedByCompanyRefs: [],
  payedByCitizenRefs: [],
  userSubscriptionNeeds: {
    0: false,
    1: false,
    2: false,
  },
};

const actions = {
  setActiveServices({ commit }) {
    settingsService
      .getAppActiveServices()
      .then((response) => {
        commit("activeServices", response.data.data);
        commit("subscriptionNeeds", response.data.data);
      })
      .catch((error) => {
        commit("clear");
        dispatch("alert/error", "Errore app settings", { root: true });
        return Promise.reject(error);
      });
  },
  setSubscriptionSettings({ commit }, data) {
    console.log(data);
    commit("subscriptionNeeds", {
      0: true,
      1: false,
      2: false,
    });
  },
  clear({ commit }) {
    commit("clear");
  },
};

const mutations = {
  activeServices(state, services) {
    state.actives = services;
    state.activeRefs = services.map((service) => service.position_ref);
  },
  subscriptionNeeds(state, services) {
    state.payedByCompanyRefs = services
      .filter((service) => service.payed_by_company == true)
      .map((service) => service.position_ref);

    state.payedByCitizenRefs = services
      .filter((service) => service.payed_by_citizen == true)
      .map((service) => service.position_ref);

    state.userSubscriptionNeeds = {
      0: state.payedByCitizenRefs.length > 0,
      1: state.payedByCompanyRefs.length > 0,
      2: false,
    };
  },
  clear(state) {
    state.actives = null;
    state.activeRefs = [];
    state.payedByCompanyRefs = [];
    state.payedByCitizenRefs = [];
    state.userSubscriptionNeeds = {
      0: false,
      1: false,
      2: false,
    };
  },
};

export const settings = {
  namespaced: true,
  state,
  actions,
  mutations,
};

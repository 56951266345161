<template>
  <div class="p-4 mt-4 text-center" v-if="canStartVerification">
    <b-col class="col-12 align-content-center py-2 py-md-3 py-xl-4">
      <div class="mx-auto col-10 col-md-4 col-xl-3 py-2">
        <b-img
          :src="require(`@/assets/${$root.scope}/logo.svg`)"
          fluid-grow
          alt="logo app"
        ></b-img>
      </div>
    </b-col>
    <h2>Verifica il tuo numero di telefono</h2>
    <OtpValidation
      :user="user"
      :cause="1"
      :validatable="user.hashid"
      @otp-validated="handleOtpValidation($event)"
      class="text-center"
    >
      <template v-slot:instruction>
        <h6 class="my-4">
          Per utilizzare la piattaforma è necessario completare la procedura di
          registrazione verificando il numero di telefono indicato.
          <router-link
            class="text-secondary underlined"
            :to="{
              name: user.type === 0 ? 'profilo cittadino' : 'profilo operatore',
            }"
          >
            Controlla il numero indicato .
          </router-link>
          <br />
          <br />
          Clicca su "invia codice di verifica" per inviare il codice OTP di
          accesso via SMS al numero indicato.
        </h6>
      </template>
    </OtpValidation>
  </div>
  <div v-else class="p-4 mt-4 text-center">
    <b-col class="col-12 align-content-center py-2 py-md-3 py-xl-4">
      <div class="mx-auto col-10 col-md-4 col-xl-3 py-2">
        <b-img
          :src="require(`@/assets/${$root.scope}/logo.svg`)"
          fluid-grow
          alt="logo app"
        ></b-img>
      </div>
    </b-col>
    <h2>Inserisci il tuo numero di telefono</h2>

    <h6 class="my-4">
      Per utilizzare la piattaforma è necessario inserire e verificare il numero
      di telefono.
      <br />
    </h6>
    <b-overlay :show="loading">
      <b-form @submit.prevent="saveNumber">
        <b-form-group
          label="Numero di telefono:"
          label-for="tel_number"
          class="text-center text-font-grey mx-auto col-12 px-1 col-md-6 px-md-2 mt-4"
        >
          <b-input-group class="">
            <b-input-group-prepend>
              <h6 class="mb-4 mt-2 pt-2 pr-1 text-prim-grad-1">+39</h6>
            </b-input-group-prepend>
            <b-form-input
              class="mb-3"
              name="tel_number"
              type="text"
              placeholder="1234..."
              v-model="tel_number"
              minlength="9"
              required
              :state="tel_number !== null ? validNumber : null"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-button variant="secondary text-white fw-bolder" type="submit"
          >Salva numero</b-button
        >
      </b-form>
    </b-overlay>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import OtpValidation from "@/app/common/components/utilities/OtpValidation.vue";
export default {
  name: "NumberVerification",
  components: {
    OtpValidation,
  },
  computed: {
    validNumber() {
      if (this.tel_number) {
        const prefix = this.tel_number.slice(0, 2);
        const hasPrefix = prefix === "00" || this.tel_number.indexOf("+") >= 0;
        return !isNaN(this.tel_number) && !hasPrefix;
      }
      return false;
    },
    canStartVerification: function () {
      if (this.user && this.user.anagrafica) {
        return this.user.anagrafica.tel_number;
      }
      return false;
    },
    ...mapState("utente", ["status", "user"]),
  },
  watch: {
    user: {
      handler(userValue) {
        console.log(userValue);
        if (userValue && userValue.number_verified_at) {
          this.$router.push({ name: "Home" });
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("utente", ["resend", "me", "setAnagrafica"]),
    handleOtpValidation(verified) {
      if (verified) {
        const self = this;
        if (!this.user.email_verified_at) {
          this.$router.push({
            name: "EmailVerification",
            params: { status: "invalid" },
          });
          return;
        }
        this.me();
        self.$nextTick(self.$router.push({ name: "Home" }));
      }
    },
    submitResend() {
      this.resend().then(function (hh) {});
    },
    saveNumber() {
      if (!this.validNumber) {
        return false;
      }
      this.loading = true;
      //  var self = this;
      this.setAnagrafica({
        form: { tel_number: this.tel_number },
        isPost: false,
      })
        .then((res) => {
          this.tel_number = null;
        })
        .catch((err) => {
          console.log("ERRR");
        });
    },
  },
  data() {
    return {
      tel_number: null,
      loading: false,
    };
  },
  mounted() {
    if (this.user.anagrafica && this.user.anagrafica.tel_number) {
      this.tel_number = this.user.anagrafica.tel_number;
    }
  },
};
</script>
